import React from 'react';

import * as actions from './_actions';

export const useLogout = () => {
  const logout = React.useCallback((cb) => {
    actions.logout(cb);
  }, []);

  return [logout];
};
