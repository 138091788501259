import React from 'react';

import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { IoCalendarSharp } from 'react-icons/io5';

import FormInput from './_FormInput';
import FormInputSelect from './_FormInputSelect';
import useOnClickOutside from '../../hooks/useOnClickOutside';

function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M11 17l-5-5m0 0l5-5m-5 5h12"
      />
    </svg>
  );
}

export const formatDateRangeText = (
  value,
  { hasAllTime = false, isTodayLabel = false } = {}
) => {
  const date = value || {};
  const { from, to } = date;

  const today = dayjs().format('MMM DD, YYYY');
  let $from = from ? dayjs(from).format('MMM DD, YYYY') : '';
  const $to = to ? dayjs(to).format('MMM DD, YYYY') : '';

  if (!$from && !$to && hasAllTime) return 'All Time';

  if ($from === $to) {
    if (today === $from)
      return isTodayLabel ? 'Today' : dayjs().format('MMM DD, YYYY');
    return $from;
  }

  if (dayjs(from).format('YYYY') === dayjs(to).format('YYYY')) {
    $from = dayjs(from).format('MMM DD');
  }

  return `${$from} - ${$to}`;
};

function CustomHeader({ props }) {
  const {
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    monthsShown,
  } = props;
  const isFrom = customHeaderCount === 1;

  return (
    <div className="relative pb-3 flex justify-center">
      <button
        type="button"
        aria-label="change"
        className={cn('absolute hover:bg-gray-100 p-1 px-1.5 rounded', {
          'right-2 rotate-180': isFrom,
          'left-2': !isFrom,
        })}
        onClick={isFrom ? increaseMonth : decreaseMonth}
      >
        <ArrowIcon />
      </button>
      <div className="font-bold text-base">
        {dayjs(monthDate).format('MMMM YYYY')}
      </div>
      {monthsShown === 1 && (
        <button
          type="button"
          aria-label="change"
          className="absolute hover:bg-gray-100 p-1 px-1.5 rounded rotate-180 right-2"
          onClick={isFrom ? decreaseMonth : increaseMonth}
        >
          <ArrowIcon />
        </button>
      )}
    </div>
  );
}

CustomHeader.defaultProps = {
  monthDate: new Date(),
  customHeaderCount: 0,
  monthsShown: 1,
  decreaseMonth: () => {},
  increaseMonth: () => {},
};

CustomHeader.propTypes = {
  monthDate: PropTypes.instanceOf(Date),
  props: PropTypes.instanceOf(Object).isRequired,
  customHeaderCount: PropTypes.number,
  monthsShown: PropTypes.number,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
};

export const DATE_RANGE_OPTS = [
  {
    label: 'Custom',
    value: 'custom',
    data: {
      from: '',
      to: '',
    },
  },
  {
    label: 'Today',
    value: 'today',
    data: {
      from: dayjs(),
      to: dayjs(),
    },
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    data: {
      from: dayjs().subtract(1, 'days'),
      to: dayjs().subtract(1, 'days'),
    },
  },
  {
    label: 'Last 7 Days',
    value: 'last_7_days',
    data: {
      from: dayjs().subtract(6, 'days'),
      to: dayjs(),
    },
  },
  {
    label: 'Last 30 Days',
    value: 'last_30_days',
    data: {
      from: dayjs().subtract(29, 'days'),
      to: dayjs(),
    },
  },
  {
    label: 'Last Month',
    value: 'last_month',
    data: {
      from: dayjs().subtract(1, 'months').startOf('month'),
      to: dayjs().subtract(1, 'months').endOf('month'),
    },
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_months',
    data: {
      from: dayjs()
        .subtract(6, 'months')
        .startOf('month')
        .format('MMM DD, YYYY'),
      to: dayjs().subtract(1, 'months').endOf('month').format('MMM DD, YYYY'),
    },
  },
  {
    label: 'Last 9 Months',
    value: 'last_9_months',
    data: {
      from: dayjs()
        .subtract(9, 'months')
        .startOf('month')
        .format('MMM DD, YYYY'),
      to: dayjs().subtract(1, 'months').endOf('month').format('MMM DD, YYYY'),
    },
  },
  {
    label: 'Last 12 Months',
    value: 'last_12_months',
    data: {
      from: dayjs()
        .subtract(12, 'months')
        .startOf('month')
        .format('MMM DD, YYYY'),
      to: dayjs().subtract(1, 'months').endOf('month').format('MMM DD, YYYY'),
    },
  },
  {
    label: 'Last Year',
    value: 'last_year',
    data: {
      from: dayjs().subtract(1, 'years').startOf('year'),
      to: dayjs().subtract(1, 'years').endOf('year'),
    },
  },
];

function FormDateRangePicker({
  name,
  label,
  error,
  value,
  required,
  onChange,
  dateFormat,
  monthsShown,
  placeholder,
  labelClassName,
  onSetFieldValue,
  positionClassName,
  containerClassName,
  hasAllTime,
  ...rest
}) {
  const ref = React.useRef();
  const [isShow, setIfShow] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const dateRangeOpts = React.useMemo(() => {
    if (hasAllTime) {
      return [
        ...DATE_RANGE_OPTS,
        {
          label: 'All Time',
          value: 'all_time',
          data: {},
        },
      ];
    }
    return DATE_RANGE_OPTS;
  }, [hasAllTime]);

  useOnClickOutside(ref, setIfShow);

  const [date, setDate] = React.useState({
    from: value?.from ? dayjs(value?.from).format(dateFormat) : value?.from,
    to: value?.to ? dayjs(value?.to).format(dateFormat) : value?.to,
    range: value?.range,
  });

  const handleShowDate = () => {
    if (!isShow) {
      setIfShow(true);
      setTimeout(() => {
        setAnimate(true);
      }, 100);
      return;
    }

    setDate({
      from: value?.from,
      to: value?.to,
      range: value?.range,
    });

    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);
  };

  const handleOnChangeDate = ([start, end]) => {
    const args = {
      ...date,
      from: start,
      to: end,
    };
    setDate(args);
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, args);
    }

    if (typeof onChange === 'function')
      onChange((prevState) => ({
        ...prevState,
        [name]: args,
      }));
  };

  const handleOnChangeSelect = ($v) => {
    const v = $v()?.range;
    setDate((prev) => ({
      ...prev,
      range: v,
      from: v?.data?.from,
      to: v?.data?.to,
    }));
  };

  const handleDateSelect = () => {
    setDate((prev) => ({
      ...prev,
      range: dateRangeOpts[0],
    }));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleShowDate();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const renderTo = () => {
      if (date?.from && !date?.to) {
        setDate((prev) => ({
          ...prev,
          to: dayjs(date?.from).format(dateFormat),
        }));

        return dayjs(date?.from).format(dateFormat);
      }

      return date?.to ? dayjs(date?.to).format(dateFormat) : '';
    };

    const args = {
      ...date,
      from: date?.from ? dayjs(date?.from).format(dateFormat) : '',
      to: renderTo(),
    };

    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, args);
      setIfShow(false);
      return;
    }

    if (typeof onChange === 'function')
      onChange((prevState) => ({
        ...prevState,
        [name]: args,
      }));

    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);
  };

  const renderPlaceholder = React.useMemo(() => {
    const { from, to } = date;
    if (from && to) {
      const f = dayjs(from).format(dateFormat);
      const t = dayjs(to).format(dateFormat);

      const renderDate = f === t ? 'Today' : `${f} - ${t}`;
      return renderDate;
    }
    return placeholder;
  }, [date, dateFormat, placeholder]);

  React.useEffect(() => {
    if (value?.from === '' && value?.to === '' && value?.range === '') {
      setDate({
        from: '',
        to: '',
        range: '',
      });
    }
  }, [value?.from, value?.range, value?.to]);

  return (
    <div className="w-full relative" ref={ref}>
      {label && (
        <label
          className={cn('form-label', labelClassName)}
          htmlFor={rest?.id ?? name}
        >
          {label} {required ? <span className="text-red-500">*</span> : ''}
        </label>
      )}
      <button
        className={cn(
          'transition-all duration-300 ease-in-out flex space-x-2 disabled:bg-gray-100 disabled:opacity-100',
          containerClassName,
          {
            'form-input': !error,
            'form-input-error': error,
          }
        )}
        onClick={handleShowDate}
        type="button"
        id={rest?.id ?? name}
      >
        <IoCalendarSharp className="h-5 w-5" />
        <div className="flex-1 text-left">{renderPlaceholder}</div>
      </button>
      {error && (
        <small className="flex text-xs absolute -bottom-2 right-2 px-2 bg-red-50 border rounded text-red-500">
          {error ?? ''}
        </small>
      )}
      {isShow && (
        <div
          className={cn(
            'px-4 pb-4 pt-2 mt-1',
            'origin-top z-50 absolute',
            'transition transform ease-out duration-100',
            'bg-white border border-gray-100 rounded-md shadow h-auto',
            'max-w-screen-sm min-w-max',
            {
              'opacity-0 scale-95': !animate,
              'opacity-100 scale-100': animate,
            },
            {
              [positionClassName]: positionClassName,
            }
          )}
        >
          <div
            className={cn({
              'flex flex-col xl:flex-row xl:gap-3': monthsShown > 1,
              'flex flex-col': monthsShown === 1,
            })}
          >
            <div>
              <DatePicker
                inline
                selected={date?.from ? new Date(dayjs(date?.from)) : ''}
                startDate={date?.from ? new Date(dayjs(date?.from)) : ''}
                endDate={date?.to ? new Date(dayjs(date?.to)) : ''}
                onChange={handleOnChangeDate}
                selectsRange
                shouldCloseOnSelect={false}
                focusSelectedMonth={false}
                id={rest?.id || name}
                monthsShown={monthsShown}
                name={name}
                renderCustomHeader={(props) => (
                  <CustomHeader
                    props={{
                      ...props,
                      monthsShown,
                    }}
                  />
                )}
                calendarClassName="customize-datepicker-theme"
                disabledKeyboardNavigation
                onSelect={handleDateSelect}
              />
            </div>
            <div
              className={cn('xl:mr-auto flex flex-col gap-3 xl:pt-2', {
                'w-60': monthsShown === 1,
              })}
            >
              <FormInputSelect
                label="Date Range"
                name="range"
                isSorted={false}
                options={dateRangeOpts}
                onChange={handleOnChangeSelect}
                value={date?.range ?? ''}
                containerClassName="customize-select-theme"
              />
              <div
                className={cn('flex-1 grid gap-3', {
                  'grid-cols-2': monthsShown > 1,
                  'grid-cols-1': monthsShown < 2,
                })}
              >
                <FormInput
                  label="From"
                  name="from"
                  readOnly
                  value={date?.from ? dayjs(date?.from).format(dateFormat) : ''}
                  containerClassName="customize-input-theme"
                  placeholder="Start date"
                />
                <FormInput
                  label="To"
                  name="to"
                  readOnly
                  value={date?.to ? dayjs(date?.to).format(dateFormat) : ''}
                  containerClassName="customize-input-theme"
                  placeholder="End date"
                />
              </div>
              <div className="flex items-center justify-end space-x-2">
                <button
                  type="button"
                  className="rounded border border-primary-500 p-2 w-full"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="rounded w-full p-2 bg-primary-500 text-white"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
FormDateRangePicker.defaultProps = {
  error: false,
  monthsShown: 2,
  required: false,
  label: 'Date Range',
  labelClassName: '',
  value: {
    from: '',
    to: '',
    range: {
      label: 'Custom',
      value: '',
      data: '',
    },
  },
  onChange: false,
  onSetFieldValue: false,
  hasAllTime: false,
  dateFormat: 'MMM DD, YYYY',
  positionClassName: 'left-0',
  containerClassName: '',
  placeholder: 'Select date range...',
};

FormDateRangePicker.propTypes = {
  required: PropTypes.bool,
  dateFormat: PropTypes.string,
  monthsShown: PropTypes.number,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  positionClassName: PropTypes.string,
  hasAllTime: PropTypes.bool,
  onChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
};

export default FormDateRangePicker;
