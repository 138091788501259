import { isEmpty, isUndefined, mapValues, omitBy } from 'lodash';

export const removeNull = (obj) =>
  omitBy(obj, (x) => typeof x === 'undefined' || x === null);

export const removeEmpty = (obj) =>
  omitBy(obj, (x) => isEmpty(`${x}`) || isUndefined(`${x}`));

export const convertNullToStringOfObject = (obj) =>
  mapValues(obj, (v) => (v === null ? '' : v));
