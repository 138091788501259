import React from 'react';

import { useMutation } from 'services';
import { storage } from 'utils';
import { toastSuccess } from 'ui/toast';

import * as actions from './_actions';

export const useSignUp = () => {
  const { isLoading, mutate } = useMutation(actions.signUp);

  const signup = React.useCallback((type, payload, cb) => {
    mutate(
      { type, payload },
      {
        onSuccess: async (res) => {
          const { token = '', user, permissions = [] } = res;
          storage.set('profile', {
            full_name: user?.full_name,
            email: user?.email,
            user_type: type,
          });

          storage.set('permissions', permissions);

          storage.set('_token', token);
          storage.set(
            '_user_key',
            `${user?.id}-${new Date(user?.updated_at).getTime()}`
          );
          await toastSuccess('Account has been successfully registered.');
          cb();
        },
      }
    );
  }, []);

  return [isLoading, signup];
};

export const useLogin = () => {
  const { isLoading, mutate } = useMutation(actions.login);

  const login = React.useCallback((type, payload, cb) => {
    mutate(
      { type, payload },
      {
        onSuccess: async (res) => {
          const { token = '', user, permissions = [] } = res;
          storage.set('profile', {
            full_name: user?.full_name,
            email: user?.email,
            user_type: type,
          });

          storage.set('permissions', permissions);
          storage.set('_token', token);
          storage.set(
            '_user_key',
            `${user?.id}-${new Date(user?.updated_at).getTime()}`
          );
          cb();
        },
      }
    );
  }, []);

  return [isLoading, login];
};

export const useOtpConfirm = () => {
  const { isLoading, mutate } = useMutation(actions.verifyOtpCode);

  const verify = React.useCallback((payload, resolve, reject) => {
    mutate(
      { payload },
      {
        onSuccess: () => {
          resolve();
        },
        onError: (e) => {
          reject(e);
        },
      }
    );
  }, []);

  return [isLoading, verify];
};
