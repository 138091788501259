import React from 'react';

import Brand from './_Brand';

function ScreenLoader() {
  return (
    <div className="flex w-screen h-screen bg-primary-500">
      <div className="m-auto text-center space-y-1">
        <div className="flex justify-center">
          <Brand className="h-10" type="wide" />
        </div>
        <div className="flex items-center justify-center space-x-2">
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <h4 className="font-normal text-lg text-white">Please wait...</h4>
        </div>
      </div>
    </div>
  );
}

ScreenLoader.defaultProps = {};

ScreenLoader.propTypes = {};

export default ScreenLoader;
