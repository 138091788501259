import React from 'react';

import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import { ROOT_ACTION } from '../_constants';

const closeIcon = (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="w-5 h-5 transition duration-300 ease-in-out group-hover:text-white text-gray-600 rounded-full"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

function CloseButton({ loading }) {
  const { onClose } = React.useContext(ROOT_ACTION);

  return (
    <div className="ml-auto mt-3 mb-2">
      <button
        disabled={loading}
        type="button"
        tabIndex="-1"
        className="rounded-full transition duration-300 ease-in-out group outline-none group hover:bg-gray-600 p-1 -mr-1"
        onClick={loading ? noop : onClose}
      >
        {closeIcon}
      </button>
    </div>
  );
}
CloseButton.defaultProps = {
  loading: false,
};

CloseButton.propTypes = {
  loading: PropTypes.bool,
};

export default CloseButton;
