import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const inactiveIcon = (
  <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
    <path
      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const activeIcon = (
  <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 12 12">
    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
  </svg>
);

const loadingIcon = (
  <svg
    className="w-3 h-3 animate-spin"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
      clipRule="evenodd"
    />
  </svg>
);

const FormInputSwitch = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    value,
    required,
    onChange,
    disabled,
    className,
    isLoading,
    disableOnOff,
    labelClassName,
    onSetFieldValue,
    activeClassName,
    ...rest
  } = props;

  const handleOnSwitch = (e) => {
    e.preventDefault();
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, !value);
      return;
    }
    if (typeof onChange === 'function')
      onChange((prev) => ({ ...prev, [name]: !value }));
  };

  return (
    <div
      className={cn('relative', {
        'gap-3 flex items-center': label,
        'flex items-center justify-center': !label,
      })}
      ref={ref}
    >
      <button
        type="button"
        className={cn(
          'relative inline-flex flex-shrink-0 h-6 w-12 focus:outline-none',
          'border-2 border-transparent rounded-full ',
          'transition-colors ease-in-out duration-200 ',
          'focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
          className,
          {
            'bg-gray-200': !value,
            [activeClassName]: value,
          },
          {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          }
        )}
        onClick={handleOnSwitch}
        disabled={disabled}
      >
        {!disableOnOff && (
          <div
            className={cn(
              'h-5 absolute rounded-full w-full pointer-events-none flex items-center transition ease-in-out duration-200'
            )}
          >
            <span
              className={cn('text-xs font-semibold text-white ml-1', {
                hidden: !value,
                block: value,
              })}
            >
              On
            </span>
            <span
              className={cn(
                'text-xs font-semibold text-gray-500 ml-auto mr-1',
                {
                  block: !value,
                  hidden: value,
                }
              )}
            >
              Off
            </span>
          </div>
        )}

        <span className="sr-only">SWITCH</span>
        <div
          className={cn(
            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            {
              'translate-x-0': !value,
              'translate-x-6': value,
            }
          )}
        >
          <span
            className={cn(
              'ease-in duration-150 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              {
                'opacity-100': !value,
                'opacity-0': value,
              }
            )}
          >
            {isLoading ? loadingIcon : inactiveIcon}
          </span>
          <span
            className={cn(
              'ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              {
                'opacity-0': !value,
                'opacity-100': value,
              }
            )}
          >
            {isLoading ? loadingIcon : activeIcon}
          </span>
        </div>
      </button>
      {label && (
        <label
          className={cn('form-label mb-0', labelClassName)}
          htmlFor={rest?.id || name}
        >
          {label} {required ? <span className="text-red-500">*</span> : ''}
        </label>
      )}
    </div>
  );
});

FormInputSwitch.defaultProps = {
  value: 0,
  className: '',
  label: 'Name',
  required: false,
  disabled: false,
  onChange: false,
  isLoading: false,
  labelClassName: '',
  disableOnOff: true,
  onSetFieldValue: false,
  activeClassName: 'bg-primary-500 text-primary-500',
};

FormInputSwitch.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  disableOnOff: PropTypes.bool,
};

export default FormInputSwitch;
