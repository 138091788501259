import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiCollection } from 'react-icons/hi';

import SkeletonLoader from './_SkeletonLoader';

const renderIconType = {
  gray: 'bg-gray-500',
  danger: 'bg-red-600',
  warning: 'bg-yellow-400',
  primary: 'bg-primary-500',
};

function CountCard({
  icon,
  title,
  value,
  iconType,
  isLoading,
  containerClassName,
  iconContainerClassName,
}) {
  return (
    <div className="card">
      <div
        className={cn('flex h-full gap-4', {
          [containerClassName]: containerClassName,
          'items-center': !containerClassName,
        })}
      >
        <div className="space-y-1 w-full">
          <div>
            <SkeletonLoader isLoading={isLoading}>
              <h4 className="font-semibold text-sm text-gray-600">{title}</h4>
            </SkeletonLoader>
          </div>
          <div>
            <SkeletonLoader isLoading={isLoading}>
              <h4 className="text-xl font-bold tracking-wide">{value}</h4>
            </SkeletonLoader>
          </div>
        </div>
        <div
          className={cn('ml-auto', {
            [iconContainerClassName]: iconContainerClassName,
          })}
        >
          <SkeletonLoader isLoading={isLoading} className="h-auto">
            <div className="h-full flex items-end">
              <div
                className={cn('rounded  p-2 shadow-md', {
                  [renderIconType[iconType]]: iconType,
                })}
              >
                {icon}
              </div>
            </div>
          </SkeletonLoader>
        </div>
      </div>
    </div>
  );
}
CountCard.defaultProps = {
  value: 0,
  title: 'Label',
  isLoading: false,
  iconType: 'primary',
  icon: <HiCollection />,
  containerClassName: false,
  iconContainerClassName: false,
};

CountCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  isLoading: PropTypes.bool,
  iconType: PropTypes.string,
  iconContainerClassName: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  containerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default CountCard;
