import React from 'react';

import PropTypes from 'prop-types';

function SectionTitle({ title }) {
  return <h1 className="text-xl font-semibold tracking-wide">{title}</h1>;
}
SectionTitle.defaultProps = {
  title: '',
};

SectionTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionTitle;
