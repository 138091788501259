import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PageLoader from './_PageLoader';

const wait = (t) =>
  new Promise((r) => {
    setTimeout(r, t);
  });

function Page404({ duration, withDelay }) {
  const [show, setShow] = React.useState(false);

  const dispatchDelay = async () => {
    await wait(duration);
    setShow(true);
  };

  React.useEffect(() => {
    if (withDelay) {
      dispatchDelay();
    }
  }, [withDelay]);

  if (!show && withDelay) {
    return <PageLoader />;
  }

  return (
    <div className="w-full h-full bg-white bg-opacity-100">
      <div className="flex flex-col justify-center items-center h-full gap-12">
        <div className="text-center space-y-3">
          <div className="text-2xl font-bold">Page not found</div>
          <div className="font-light text-sm">
            Sorry, we can’t find that page you’re looking for
          </div>

          <Link to="/">
            <button className="btn primary md !mt-5 px-5" type="button">
              Go to Homepage
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

Page404.defaultProps = {
  duration: 500,
  withDelay: false,
};

Page404.propTypes = {
  duration: PropTypes.number,
  withDelay: PropTypes.bool,
};

export default Page404;
