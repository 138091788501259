import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { createRoot } from 'react-dom/client';

import QueryProvider from 'services';

const renderModalSize = {
  'modal-xs': 'max-w-lg',
  'modal-sm': 'max-w-screen-sm',
  'modal-md': 'max-w-screen-md',
  'modal-lg': 'max-w-screen-lg',
  'modal-xl': 'max-w-screen-xl',
  'modal-2xl': 'max-w-screen-2xl',
};

function Modal({ opt, onClose }) {
  const [animate, setAnimate] = React.useState(false);

  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setAnimate(false);
    onClose();
  };

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      setAnimate(false);
      onClose();
    }
  };

  React.useEffect(() => {
    setAnimate(true);
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction);
  }, []);

  const renderContent = () => {
    if (typeof opt.content === 'function')
      return (
        <div
          className={cn('flex flex-col flex-1 px-5 pt-3 pb-4', {
            [opt?.containerClassName]: opt?.containerClassName,
          })}
        >
          {opt.content(handleOnClose)}
        </div>
      );
    if (typeof opt.content === 'string')
      return (
        <div
          className={cn('px-5 pt-3 pb-4', {
            [opt?.containerClassName]: opt?.containerClassName,
          })}
        >
          {opt.content}
        </div>
      );
    return 'n/a';
  };

  return (
    <div className="relative z-40">
      <div
        className={cn(
          'fixed inset-0 bg-black bg-opacity-30 transition-opacity',
          {
            'opacity-100 ease-out duration-300': animate,
            'opacity-0 ease-in duration-200': !animate,
          }
        )}
      />
      {opt?.escButton && (
        <button
          type="button"
          aria-label="Close"
          onClick={handleOnClose}
          className="fixed top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50"
        >
          <svg
            className="fill-current text-black"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span className="text-sm">(Esc)</span>
        </button>
      )}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 pb-10 md:pb-4 md:px-4 text-center sm:p-0">
          <div
            className={cn(
              'relative bg-white rounded-md text-left w-full shadow-xl transform transition-all',
              {
                'opacity-100 ease-out duration-300 translate-y-4 sm:scale-100':
                  animate,
                'opacity-0 ease-in duration-200 translate-y-0 sm:scale-90':
                  !animate,
              },
              {
                [renderModalSize[opt.modalSize]]: opt.modalSize,
              }
            )}
          >
            <div
              className={cn('bg-primary-500 rounded-t', {
                [opt.titleClassName]: opt.titleClassName,
                'px-5 py-3': !opt?.titleClassName,
              })}
            >
              <div className="flex items-center">
                {opt.title && (
                  <div className="text-lg md:text-xl text-white font-semibold">
                    {opt?.title}
                  </div>
                )}
                {!opt.noClose && (
                  <div className="ml-auto">
                    <button
                      type="button"
                      aria-label="Close"
                      tabIndex="-1"
                      className="rounded-full transition duration-300 ease-in-out group outline-none group p-1"
                      onClick={handleOnClose}
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-5 h-5 transition duration-300 ease-in-out text-white rounded-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <>
              {opt.focusTrap ? (
                <FocusTrap
                  focusTrapOptions={{
                    allowOutsideClick: () => true,
                  }}
                >
                  {renderContent()}
                </FocusTrap>
              ) : (
                renderContent()
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

let appRoot;

export const closeModal = () => {
  const prevElem = document.activeElement;
  const root = document.getElementById('modal-root');
  if (!appRoot) appRoot = createRoot(root);
  if (!root) {
    alert('Modal root not found!'); // eslint-disable-line
    return;
  }
  setTimeout(() => {
    document.body.removeAttribute('class');
    appRoot.render(null);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, 'Modal Marker Error!!');
      }
    }, 100);
  }, 200);
};

export const showModal = (options) => {
  const opt = {
    title: false,
    modalSize: 'modal-md',
    titleClassName: false,
    content: 'Modal Body',
    noClose: false,
    escButton: false,
    titleContainer: true,
    focusTrap: true,
    onCloseCallback: false,
    containerClassName: false,
    withProvider: false,
    ...options,
  };

  const root = document.getElementById('modal-root');
  const prevElem = document.activeElement;
  document.body.className = 'overflow-hidden';

  if (!appRoot) appRoot = createRoot(root);

  const onClose = () => {
    try {
      if (opt.onCloseCallback) {
        opt.onCloseCallback();
      }
      setTimeout(() => {
        document.body.removeAttribute('class');
        appRoot.render(null);
        setTimeout(() => {
          try {
            prevElem.blur();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'Modal Marker Error!!');
          }
        }, 100);
      }, 200);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, ' here');
    }
  };

  if (!root) {
    alert('Toast root not found!'); // eslint-disable-line
    return;
  }

  appRoot.render(
    <QueryProvider>
      <Modal opt={opt} onClose={onClose} />
    </QueryProvider>
  );
};

function ModalMarker() {
  return <div id="modal-root" />;
}

export default React.memo(ModalMarker);
