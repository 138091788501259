import React from 'react';

import PropTypes from 'prop-types';

function FormInputPin({
  name,
  value,
  count,
  error,
  onChange,
  pinClassName,
  activePinClassName,
  inActivePinClassName,
  onSetFieldValue,
  ...rest
}) {
  const inputRef = React.useRef();

  const keepFocus = () => {
    try {
      inputRef.current.focus();
    } catch (err) {
      // do nothing...
    }
  };

  React.useEffect(() => {
    keepFocus();
  }, []);

  const handleChange = ({ target }) => {
    if (
      typeof onSetFieldValue === 'function' &&
      target?.value.length < count + 1
    )
      onSetFieldValue(name, target?.value);

    if (typeof onChange === 'function' && target?.value.length < count + 1)
      onChange({ [name]: target?.value });
  };

  return (
    <>
      <div className="flex text-left items-left justify-left relative">
        <input
          type="number"
          name={name}
          value={value}
          ref={inputRef}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={handleChange}
          autoComplete="one-time-code"
          className="absolute opacity-0 w-full inset-0"
          {...rest}
        />
        <div className="flex justify-center items-center w-full xs:space-x-1 space-x-2 md:space-x-4">
          {Array.from({ length: count }, (_, key) => key).map((item, i) => (
            <div
              key={item}
              className={`flex items-center justify-center ${pinClassName} ${
                value?.length === item
                  ? activePinClassName
                  : inActivePinClassName
              }`}
            >
              {value[i] || ''}
            </div>
          ))}
        </div>
        {error && (
          <small className="flex text-xs absolute -bottom-5 right-2 px-2 bg-red-50 rounded text-red-500">
            {error?.message ?? ''}
          </small>
        )}
      </div>
    </>
  );
}

FormInputPin.defaultProps = {
  value: '',
  count: 6,
  pinClassName: 'w-10 h-10 text-gray-500 text-lg border',
  activePinClassName: 'border-gray-500',
  inActivePinClassName: 'border-gray-700',
  error: false,
  onChange: false,
  onSetFieldValue: false,
};

FormInputPin.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.number,
  pinClassName: PropTypes.string,
  activePinClassName: PropTypes.string,
  inActivePinClassName: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
};

export default FormInputPin;
