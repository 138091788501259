import React, { useState } from "react";

import { Link, useSearchParams } from "react-router-dom";

import ThemePageLayout from "layouts/page-theme";

function MessageBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [closeable, setCloseable] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [nextLocation, setNextLocation] = useState("/");
  const [buttonLabel, setButtonLabel] = useState("Go to homepage");

  React.useEffect(() => {
    setTitle(searchParams.get("title") || "Internal Server Error");
    setMessage(
      searchParams.get("msg") || "Sorry, something went wrong in our end"
    );
    setCloseable(searchParams.get("closeable") === "true");
    setNextLocation(searchParams.get("redirectTo") || "/");
    setButtonLabel(searchParams.get("label") || "Go to homepage");
    setSearchParams({});
  }, []);

  const closeMe = () => {
    window.close();
  };

  return (
    <ThemePageLayout>
      <div className="flex flex-col justify-center items-center h-full gap-12">
        <div className="text-message-box text-center">
          <div className="text-message-title text-4xl font-bold">{title}</div>
          <div className="text-message-content text-xl font-normal">{message}</div>
          {closeable && (
            <Link to="/">
              <button
                className="btn primary md !mt-5 px-5"
                onClick={closeMe}
                type="button"
              >
                Close me
              </button>
            </Link>
          )}
          {!closeable && (
            <Link
              to={nextLocation}
              className="btn primary md !mt-5 px-5"
              type="button"
            >
              {buttonLabel}
            </Link>
          )}
        </div>
      </div>
    </ThemePageLayout>
  );
}

MessageBox.defaultProps = {};

MessageBox.propTypes = {};

export default MessageBox;
