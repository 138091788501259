import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { createRoot } from 'react-dom/client';

import { renderColor, renderIconType } from './_constants';

const disabledScroll = () => {
  const TopScroll = window.pageYOffset || document.documentElement.scrollTop;
  const LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
  window.onscroll = () => {
    window.scrollTo(LeftScroll, TopScroll);
  };
};

const enableScroll = () => {
  window.onscroll = () => {};
};

const renderModalSize = {
  'modal-xs': 'max-w-lg',
  'modal-sm': 'max-w-screen-sm',
  'modal-md': 'max-w-screen-sm lg:max-w-screen-md',
  'modal-lg': 'max-w-screen-lg',
  'modal-xl': 'max-w-screen-xl',
  'modal-2xl': 'max-w-screen-2xl',
};

function Alert({ opt, onClose }) {
  const [animate, setAnimate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setAnimate(false);
    onClose();
  };

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      setAnimate(false);
      onClose();
    }
  };

  const handleYes = (e) => {
    e.preventDefault();
    setIsLoading(true);
    opt.onYes(() => {
      setIsLoading(false);
      setAnimate(false);
      onClose();
    });
  };

  const handleNo = (e) => {
    e.preventDefault();
    setIsLoading(true);
    opt.onNo(() => {
      setIsLoading(false);
      setAnimate(false);
      onClose();
    });
  };

  const renderContent = () => {
    if (typeof opt.content === 'function')
      return (
        <div className={opt?.contentClassName}>{opt.content(onClose)}</div>
      );
    if (typeof opt.content === 'string')
      return <div className="px-3 whitespace-pre-line">{opt.content}</div>;
    return 'n/a';
  };

  React.useEffect(() => {
    setAnimate(true);
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction);
  }, []);

  return (
    <div className="relative z-50">
      <div
        className={cn(
          'fixed inset-0 bg-black bg-opacity-30 transition-opacity',
          {
            'opacity-100 ease-out duration-300': animate,
            'opacity-0 ease-in duration-200': !animate,
          }
        )}
      />
      {opt?.escButton && (
        <button
          type="button"
          onClick={handleOnClose}
          className="fixed top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50"
        >
          <svg
            className="fill-current text-black"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
          <span className="text-sm">(Esc)</span>
        </button>
      )}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 pb-10 md:pb-4 text-center sm:p-0">
          <div
            className={cn(
              'relative bg-white rounded-md px-4 py-5 sm:p-6 text-left w-full shadow-xl transform transition-all',
              {
                'opacity-100 ease-out duration-300 translate-y-4 sm:scale-100':
                  animate,
                'opacity-0 ease-in duration-200 translate-y-0 sm:scale-90':
                  !animate,
              },
              {
                [renderModalSize[opt.modalSize]]: opt.modalSize,
              }
            )}
          >
            <div className="flex space-x-5">
              <div className="flex-shrink-0">
                <div
                  className={`p-2 rounded-full ${
                    renderColor[`bg-${opt?.type}`]
                  }`}
                >
                  <svg
                    className={`h-10 w-10 ${renderColor[`icon-${opt?.type}`]}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    {renderIconType[opt?.type]}
                  </svg>
                </div>
              </div>
              <div className="ml-auto">
                {opt.focusTrap ? (
                  <FocusTrap
                    focusTrapOptions={{
                      allowOutsideClick: () => true,
                    }}
                  >
                    {renderContent()}
                  </FocusTrap>
                ) : (
                  renderContent()
                )}
              </div>
            </div>
            <div className={opt.buttonContainerClassName}>
              {opt.onNoLabel && (
                <button
                  className={cn(opt.onNoClassName, {
                    '!cursor-wait': isLoading || opt?.disabled,
                  })}
                  type="button"
                  onClick={handleNo}
                  disabled={isLoading || opt?.isLoading || opt?.disabled}
                >
                  {opt.onNoLabel}
                </button>
              )}
              {opt.onYesLabel && (
                <button
                  className={cn(opt.onYesClassName, {
                    '!cursor-wait': isLoading || opt?.disabled || opt?.isLoading,
                  })}
                  type="button"
                  onClick={handleYes}
                  disabled={isLoading || opt?.isLoading || opt?.disabled}
                >
                  {opt.onYesLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

let appRoot;

export const showAlert = (options) => {
  const opt = {
    focusTrap: false,
    type: 'warning',
    modalSize: 'modal-xs',
    escButton: false,
    isLoading: false,
    align: 'justify-center flex items-center',
    content: 'Confirmation Message',
    buttonContainerClassName: 'flex justify-end gap-2 mt-4',
    containerClassName: '',
    contentClassName: '',
    wrapperClassName: '',
    onYes: (close) => {
      close();
    },
    onYesLabel: 'Yes',
    onYesClassName: 'btn dark md w-24',
    onNo: (close) => {
      close();
    },
    onNoLabel: 'No',
    onNoClassName: 'btn line md w-24',
    ...options,
  };

  const root = document.getElementById('alert-root');
  const prevElem = document.activeElement;

  document.body.className = 'overscroll-none';

  disabledScroll();
  if (!appRoot) appRoot = createRoot(root);

  const onClose = () => {
    enableScroll();
    try {
      if (opt.onCloseCallback) {
        opt.onCloseCallback();
      }
      setTimeout(() => {
        document.body.removeAttribute('class');
        appRoot.render(null);
        setTimeout(() => {
          try {
            prevElem.blur();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'Alert Marker Error!!');
          }
        }, 100);
      }, 200);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err, ' here');
    }
  };

  appRoot.render(<Alert opt={opt} onClose={onClose} />);
};

function AlertMarker() {
  return <div id="alert-root" />;
}

export default React.memo(AlertMarker);
