import React from 'react';

import cn from 'classnames';
import {orderBy} from 'lodash';
import PropTypes from 'prop-types';
import {IoClose} from 'react-icons/io5';
import {RiLoader5Line} from 'react-icons/ri';
import {HiChevronDown} from 'react-icons/hi';
import {GoCheck, GoSearch} from 'react-icons/go';
import {ImRadioChecked} from 'react-icons/im';

const ifSelected = (selectedOption, item) => {
  if (Array.isArray(selectedOption)) {
    return (
      selectedOption?.findIndex((x) => `${x?.value}` === `${item?.value}`) > -1
    );
  }
  return false;
};

function Empty() {
  return (
    <div className="w-full flex justify-center">
      <span className="flex items-center text-gray-400 font-medium text-normal">
        No data found...
      </span>
    </div>
  );
}

const splitArray = (array) => {
  const x = array.slice(0, 20);
  const y = array.slice(20, array.length);
  return [x, y];
};

const dataToRender = (data) => {
  const [first, second] = splitArray(data);
  return {first, second};
};

const FormInputSelect = React.forwardRef((props, ref) => {
  const {
    id,
    icon,
    name,
    error,
    label,
    value,
    isGroup,
    options,
    disabled,
    required,
    onChange,
    className,
    isLoading,
    withValue,
    isMultiple,
    placeholder,
    isCreatable,
    loadOptions,
    selectAll,
    withUnselect,
    isSearchable,
    sizeClassName,
    labelClassName,
    onSetFieldValue,
    containerClassName,
  } = props;

  const clickRef = React.useRef(null);
  const isMounted = React.useRef(true);

  const [isShow, setIfShow] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [menuOption, setMenuOption] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState([]);
  const [selectedAll, setSelectedAll] = React.useState(false);

  const dispatchClose = () => {
    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);
  };

  const handleOnSetActive = (e) => {
    e.preventDefault();
    if (!isShow) {
      setIfShow(true);
      setTimeout(() => {
        setAnimate(true);
      }, 100);
      return;
    }
    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);
  };

  const handleOnSelectValue = (val) => (e) => {
    if (e) e.preventDefault();

    if (isMultiple && Array.isArray(value)) {
      setSelectedOption((prevState) => [...prevState, val]);
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, [...selectedOption, val]);
        return;
      }
      if (typeof onChange === 'function')
        onChange(
          (prev) => ({...prev, [name]: [...selectedOption, val]}),
          'increase',
          val
        );
      return;
    }

    if (isMultiple && !Array.isArray(value)) {
      setSelectedOption((prevState) => [...prevState, val]);
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, [val]);
        return;
      }
      if (typeof onChange === 'function')
        onChange((prev) => ({...prev, [name]: [val]}), 'increase', val);
      return;
    }

    if (!isMultiple && isShow) {
      setAnimate(false);
      setTimeout(() => {
        setIfShow(false);
      }, 100);
    }
    setSelectedOption([val]);
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, val);
      return;
    }
    if (typeof onChange === 'function') {
      onChange((prev) => ({...prev, [name]: val}));
    }
  };

  const handleOnUnSelectValue = (val) => (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const newSelectedOption = selectedOption.filter(
      (item) => `${item?.value}` !== `${val?.value}`
    );

    if (isMultiple) {
      setSelectedOption(newSelectedOption);
      setSelectedAll(false);
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, newSelectedOption);
        return;
      }
      onChange(
        (prev) => ({...prev, [name]: newSelectedOption}),
        'decrease',
        val
      );
      return;
    }

    if (ifSelected(selectedOption, val) && withUnselect) return;

    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);

    setSelectedOption(newSelectedOption);
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, newSelectedOption[0] || '');
      return;
    }
    if (typeof onChange === 'function') {
      onChange(
        (prev) => ({...prev, [name]: newSelectedOption[0] || ''}),
        'decrease',
        val
      );
    }
  };

  const handleOnSelectAll = (val) => (e) => {
    if (e) e.preventDefault();
    if (isMultiple) {
      setSelectedOption(val);
      setSelectedAll(true);
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, val);
        return;
      }
      if (typeof onChange === 'function')
        onChange(
          (prev) => ({...prev, [name]: val}),
          'increase',
          val
        );
    }
  };

  const handleOnUnSelectAll = () => (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isMultiple) {
      setSelectedOption([]);
      setSelectedAll(false);
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, []);
        return;
      }
      onChange(
        (prev) => ({...prev, [name]: []}),
        'decrease',
        []
      );
    }
  };

  const handleOnSearchMenu = ({target}) => {
    const val = target?.value ?? '';
    setSearchValue(val);

    if (loadOptions) {
      setLoading(true);
      setTimeout(() => {
        loadOptions(val, (res) => {
          if (!isMounted.current) return;
          setMenuOption(res);
          setLoading(false);
        });
      }, 300);
      return;
    }

    if (val) {
      const x = options.filter(
        (item) => item?.label?.toLowerCase().indexOf(val.toLowerCase()) > -1
      );
      setMenuOption(x);
      return;
    }
    setMenuOption(options);
  };

  const handleOnCreateMenu = () => {
    const v = {
      label: searchValue,
      value: searchValue,
    };

    if (!isMultiple && isShow) {
      setSelectedOption((prevState) => [...prevState, v]);
      setAnimate(false);
      setTimeout(() => {
        setIfShow(false);
        setSearchValue('');
      }, 100);

      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, v);
        return;
      }
      if (typeof onChange === 'function') {
        onChange((prev) => ({...prev, [name]: v}));
      }
      return;
    }

    if (isMultiple && isShow) {
      setSelectedOption((prevState) => [...prevState, v]);
      setAnimate(false);
      setTimeout(() => {
        setIfShow(false);
        setSearchValue('');
      }, 100);

      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, [...selectedOption, v]);
        return;
      }
      if (typeof onChange === 'function')
        onChange(
          (prev) => ({...prev, [name]: [...selectedOption, v]}),
          'increase',
          v
        );
    }
  };

  React.useEffect(() => {
    let timer;
    if (options && !loadOptions) {
      setMenuOption(options);
      setLoading(false);
    }
    if (!options && loadOptions) {
      setLoading(true);
      if (!disabled) {
        loadOptions('', (res) => {
          if (!isMounted.current) return;
          setMenuOption(res);
          setLoading(false);
        });
      }

      timer = setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
    return () => {
      clearInterval(timer);
    };
    // Check this if component get infinite loop
  }, [options, disabled]);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  React.useEffect(() => {
    if (value !== '') {
      setSelectedOption(Array.isArray(value) && isMultiple ? value : [value]);
      return;
    }
    setSelectedOption('');
  }, [isMultiple, value]);

  React.useEffect(() => {
    const escFunction = (e) => {
      if (e.keyCode === 27) {
        dispatchClose();
      }
    };

    const handleClearToggle = (e) => {
      try {
        if (clickRef.current && !clickRef.current.contains(e.target)) {
          dispatchClose();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, ' here');
      }
    };

    if (isShow) {
      document.addEventListener('keydown', escFunction, false);
      document.addEventListener('mousedown', handleClearToggle, false);
    }
    return () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('mousedown', handleClearToggle, false);
    };
  }, [id, isShow, ref]);

  const sortedMenu = menuOption.toSorted(props.sortFunction)

  const {first, second} = dataToRender(selectedOption);

  const sortedSelectedMenu = orderBy(selectedOption, 'label', 'asc');

  const renderSelectAllOption = (allowSelectMultiple, showSelectAll, hasSelectedAll, list) => {
    if (allowSelectMultiple && showSelectAll && list.length > 0) {
      return (
        <div
          key='select-all-option'
          role="presentation"
          onClick={
            !selectedAll
              ? handleOnSelectAll(list)
              : handleOnUnSelectAll()
          }
          className={cn(
            'p-2 w-full flex items-center space-x-2 hover:bg-gray-100 cursor-pointer border-b',
          )}
        >
        <span
          className={cn(
            'border border-gray-200 p-px',
            {
              'bg-gray-800 text-white': hasSelectedAll,
              'bg-white': !hasSelectedAll,
            },
            {
              rounded: allowSelectMultiple,
              'rounded-full': !allowSelectMultiple,
            }
          )}
        >
            <GoCheck
              className={cn('w-4 h-4', {
                visible: hasSelectedAll,
                invisible: !hasSelectedAll,
              })}
            />
          </span>
          <span className="text-normal font-medium text-gray-900">Select all</span>
        </div>
      )
    }

    return (
      <></>
    )
  }

  const renderOptionDropDownList = (list) => list?.map((item) => {
      const isSelected = ifSelected(selectedOption, item);
      return (
        <div
          key={item?.value}
          role="presentation"
          onClick={
            !isSelected
              ? handleOnSelectValue(item)
              : handleOnUnSelectValue(item)
          }
          className={cn(
            'p-2 w-full flex items-center space-x-2 hover:bg-gray-100 cursor-pointer',
            {
              'border-b last:border-none': !isGroup,
            }
          )}
        >
          <span
            className={cn(
              'border border-gray-200 p-px',
              {
                'bg-gray-800 text-white': isSelected,
                'bg-white': !isSelected,
              },
              {
                rounded: isMultiple,
                'rounded-full': !isMultiple,
              }
            )}
          >
            {isMultiple ? (
              <GoCheck
                className={cn('w-4 h-4', {
                  visible: isSelected,
                  invisible: !isSelected,
                })}
              />
            ) : (
              <ImRadioChecked
                className={cn('w-4 h-4', {
                  visible: isSelected,
                  invisible: !isSelected,
                })}
              />
            )}
          </span>
          <span className="text-normal font-medium text-gray-900">
            {item?.label}
          </span>
        </div>)
    }
  )

  const renderOptionList = (allowSelectMultiple, showSelectAll, hasSelectedAll, list) => {
    if (list.length > 0) {
      return (
        <>
          {allowSelectMultiple && showSelectAll && renderSelectAllOption(allowSelectMultiple, showSelectAll, hasSelectedAll, list)}
          {renderOptionDropDownList(list)}
        </>

      )
    }

    return isCreatable && searchValue ? (
      <div
        role="presentation"
        onClick={handleOnCreateMenu}
        className="flex items-center text-gray-400 font-medium p-2 cursor-pointer hover:bg-gray-50 rounded duration-200 transition ease-in-out"
      >
        Create &quot;{searchValue || ''}&quot;
      </div>
    ) : (
      <Empty/>
    );
  };

  return (
    <div className="w-full relative" ref={clickRef}>
      <div
        className={cn(
          'form-container transition duration-300 ease-in-out group',
          {
            [containerClassName]: containerClassName,
          },
          {
            'form-container': !error,
            'form-container-error': error,
          }
        )}
      >
        {label && (
          <label
            className={cn('form-label', {
              [labelClassName]: labelClassName,
            })}
            htmlFor={id ?? name}
          >
            {label} {required ? <span className="text-red-500">*</span> : ''}
          </label>
        )}
        <div className="relative w-full">
          <div
            className={cn(
              'transition duration-300 ease-in-out',
              {
                'form-input': !error,
                'form-input-error': error,
              },
              {
                'border-form-active': isShow,
              },
              {
                'cursor-not-allowed bg-gray-100': disabled,
                'cursor-pointer': !disabled,
              },
              {
                [className]: className,
              }
            )}
            role="presentation"
            tabIndex={!disabled ? 0 : -1}
            onClick={!disabled ? handleOnSetActive : () => {}}
          >
            {icon && <span className="mr-2">{icon}</span>}
            {!withValue ? (
              <div className="flex items-center">
                <span>{label || placeholder}</span>
                {selectedOption.length && isMultiple ? (
                  <div
                    className={cn('space-x-2', {
                      'has-tooltip': selectedOption.length && isMultiple,
                    })}
                  >
                    <span className="font-semibold tex-sm">:</span>
                    <span className="px-3 py-1 rounded-md text-sm font-semibold bg-gray-200">
                      {first.length}
                      {second.length ? `+` : ''}
                    </span>
                    <span className="tooltip rounded shadow-lg p-2 bg-gray-900 w-60 bg-opacity-80 text-white mt-5 text-sm">
                      {sortedSelectedMenu?.map((x) => ` ${x?.label}`).join()}
                    </span>
                  </div>
                ) : (
                  <span className="invisible">RO</span>
                )}
              </div>
            ) : (
              <div
                className={cn({
                  truncate: !isMultiple,
                })}
              >
                <div className="flex flex-wrap flex-shrink-0 gap-1">
                  <>
                    {isMultiple && selectedOption.length ? (
                      selectedOption?.map((item) => (
                        <div
                          key={item?.value}
                          className="inline-block flex-shrink-0"
                        >
                          <div
                            className={cn(
                              'items-center flex rounded-md text-sm font-semibold',
                              {
                                'bg-red-200': error,
                                'bg-gray-200': !error,
                              }
                            )}
                          >
                            <span className="pl-2 pr-1">{item?.label}</span>
                            <a
                              href="/"
                              id="remove-item"
                              onClick={handleOnUnSelectValue(item)}
                              aria-label="Remove"
                              className={cn(
                                'pr-1 outline-none hover:text-primary-500',
                                {
                                  'pointer-events-none': disabled,
                                }
                              )}
                            >
                              <IoClose className="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        {selectedOption[0]?.label || placeholder ? (
                          <span className="text-sm truncate">
                            {selectedOption[0]?.label || placeholder}
                          </span>
                        ) : (
                          <span className="text-sm invisible">RO</span>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            )}
            <div className="ml-auto h-0 flex items-center">
              {(loading || isLoading) && !isShow ? (
                <RiLoader5Line className="h-6 w-6 p-0 animate-spin" />
              ) : (
                <HiChevronDown className="h-6 w-6 p-0" />
              )}
            </div>
          </div>
        </div>
        {isShow && (
          <div
            id={id}
            className={cn(
              'transition transform ease-out duration-100 origin-top z-50 absolute right-0 mt-1 to flex flex-col bg-white border border-gray-100 rounded-md shadow h-auto max-h-80 overflow-hidden',
              {
                'opacity-0 scale-95': !animate,
                'opacity-100 scale-100': animate,
              },
              {
                [sizeClassName]: sizeClassName,
              }
            )}
          >
            {isSearchable && (
              <div className="px-3 py-1">
                <label
                  htmlFor={id || label}
                  className="text-xs text-gray-500 font-medium"
                >
                  Search{' '}
                  {typeof label === 'string' ? label || placeholder : null}
                </label>
                <div className="rounded-md border border-gray-300 transition duration-300 ease-in-out focus-within:border-gray-600 flex w-full overflow-hidden text-gray-400 focus-within:text-gray-600">
                  <div className="h-full px-2 flex m-auto">
                    <span>
                      <GoSearch className="h-5 w-5" />
                    </span>
                  </div>
                  <input
                    type="text"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    id="search-menu"
                    value={searchValue}
                    onChange={handleOnSearchMenu}
                    placeholder="Search..."
                    className="placeholder-gray-400 flex-grow outline-none sm:text-sm pl-0 pr-2 py-2 text-gray-600 disabled:bg-gray-200"
                  />
                </div>
              </div>
            )}
            <div className={cn('py-2 px-1 flex-1 flex overflow-hidden')}>
              {isShow && loading ? (
                <div className="w-full flex justify-center">
                  <span className="flex items-center text-gray-400 font-medium text-normal">
                    <RiLoader5Line className="h-6 w-6 animate-spin mr-1" />
                    {searchValue ? 'Searching...' : 'Loading...'}
                  </span>
                </div>
              ) : (
                <div className="flex-1 overflow-y-auto">
                  {isGroup ? (
                    <>
                      {sortedMenu?.map((item) => {
                        const { child } = item || [];
                        if (child.length > 0) {
                          return (
                            <div
                              key={item?.value}
                              className="pr-1 pl-2 py-2 flex flex-col items-center space-x-2 cursor-pointer"
                            >
                              <span className="text-sm tracking-wide border-b font-bold text-black w-full pb-1">
                                {item?.label}
                              </span>
                              <>
                                {renderOptionList(
                                  isMultiple,
                                  selectAll,
                                  selectedAll,
                                  item?.child
                                )}
                              </>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  ) : (
                    <>
                      {renderOptionList(
                        isMultiple,
                        selectAll,
                        selectedAll,
                        sortedMenu
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <small className=" form-error-badge">{error ?? ''}</small>}
    </div>
  );
});

FormInputSelect.defaultProps = {
  value: '',
  icon: false,
  error: false,
  className: '',
  label: 'Name',
  isGroup: false,
  options: false,
  sortFunction: (a, b) => a === b ? 0 : a < b ? 1 : -1,
  onChange: false,
  required: false,
  disabled: false,
  withValue: true,
  isLoading: false,
  isCreatable: false,
  id: 'select-menu',
  isMultiple: false,
  labelClassName: '',
  withUnselect: false,
  loadOptions: false,
  isSearchable: false,
  onSetFieldValue: false,
  selectAll: false,
  containerClassName: '',
  sizeClassName: 'w-full',
  placeholder: 'Select...',
};

FormInputSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  isGroup: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  withValue: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMultiple: PropTypes.bool,
  className: PropTypes.string,
  isCreatable: PropTypes.bool,
  withUnselect: PropTypes.bool,
  isSearchable: PropTypes.bool,
  selectAll: PropTypes.bool,
  labelClassName: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  containerClassName: PropTypes.string,
  sortFunction: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  name: PropTypes.string.isRequired,
  sizeClassName: PropTypes.string,
  onChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  loadOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  options: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.bool]),
};

export default FormInputSelect;
