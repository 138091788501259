import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const FormInput = React.forwardRef((props, ref) => {
  const {
    type,
    name,
    label,
    icon,
    error,
    prefix,
    onChange,
    required,
    className,
    iconAlign,
    labelClassName,
    onSetFieldValue,
    withShowPassword,
    containerClassName,
    disableIconInputFocus,
    errorClassName,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleOnChange = (e) => {

    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, e?.target?.value);
      return;
    }

    if (typeof onChange === 'function')      
      onChange((prev) => ({
        ...prev,
        [name]: e?.target.value,
      }));
  };

  const handleOnShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <div className="w-full relative">
        <div
          className={cn(
            'form-container transition duration-300 ease-in-out',
            containerClassName,
            {
              'form-container': !error,
              'form-container-error': error,
            }
          )}
        >
          {label && (
            <label
              className={cn('form-label', labelClassName)}
              htmlFor={rest?.id ?? name}
            >
              {label} {required ? <span className="text-red-500">*</span> : ''}
            </label>
          )}
          <div className="relative">
            {prefix && (
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <span
                  className={cn(
                    'transition duration-300 ease-in-out text-gray-400 font-light',
                    {
                      'form-input-prefix-error': error,
                      'form-input-prefix': !error,
                    }
                  )}
                >
                  {prefix}
                </span>
              </div>
            )}
            <input
              id={rest?.id ?? name}
              type={
                withShowPassword
                  ? `${showPassword ? 'text' : 'password'}`
                  : type
              }
              name={name}
              autoComplete="on"
              onChange={handleOnChange}
              className={cn(
                'transition duration-300 ease-in-out',
                className,
                {
                  'form-input': !error,
                  'form-input-error': error,
                },
                {
                  'pl-8': prefix,
                },
                {
                  'pl-3 pr-8': icon,
                }
              )}
              required={required}
              {...rest}
              ref={ref}
            />
            <>
              {withShowPassword && type === 'password' ? (
                <span
                  onClick={handleOnShowPassword}
                  role="presentation"
                  className="cursor-pointer absolute right-2 h-full top-0 flex items-center"
                >
                  {showPassword ? <HiEyeOff /> : <HiEye />}
                </span>
              ) : (
                <>
                  {icon && (
                    <span
                      className={cn('absolute h-full top-0 flex items-center', {
                        [iconAlign]: iconAlign,
                      })}
                      onClick={() => {
                        if (!disableIconInputFocus) return;

                        setTimeout(() => {
                          document.activeElement.blur();
                        }, 100);
                      }}
                      role="presentation"
                    >
                      {icon}
                    </span>
                  )}
                </>
              )}
            </>
          </div>
        </div>
        {error && (
          <small className={cn('form-error-badge', errorClassName)}>
            {error ?? ''}
          </small>
        )}
      </div>
    </div>
  );
});

FormInput.defaultProps = {
  label: 'Name',
  containerClassName: '',
  labelClassName: '',
  errorClassName: '',
  className: '',
  required: false,
  type: 'text',
  error: false,
  icon: false,
  withShowPassword: false,
  onSetFieldValue: false,
  onChange: false,
  prefix: false,
  disableIconInputFocus: false,
  iconAlign: 'right-2',
};

FormInput.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  withShowPassword: PropTypes.bool,
  name: PropTypes.string.isRequired,
  iconAlign: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disableIconInputFocus: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  prefix: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
};

export default FormInput
