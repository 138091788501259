import React from "react";

import { createRoot } from "react-dom/client";

import Loader from "./_Loader";
import { renderIconType, renderColor, renderTitle } from "./_constants";

const toastUniqueID = "toast-root";

function ToastMarker(props) {
  return <div id={toastUniqueID} {...props} />;
}

let appRoot;
let toastTimeout = null;

const wait = (t) =>
  new Promise((r) => {
    setTimeout(r, t);
  });

const toast = async (content, options = {}) => {
  const opt = {
    timeout: 4000,
    title: false,
    divId: "toast-override",
    position: "bottom-center md:top-right",
    type: "success",
    ...options,
    content,
  };

  const root = document.getElementById(toastUniqueID);
  if (!appRoot) appRoot = createRoot(root);

  if (!root) {
    alert("Toast root not found!"); // eslint-disable-line
    return;
  }

  const close = async () => {
    appRoot.render(null);
    if (opt.cb) {
      opt.cb();
    }
  };

  close();
  await wait(5);

  const renderContent = () => {
    if (typeof opt.content === "function") {
      return (
        <div>{opt.content(close)}</div>
      );
    }
    return opt.content;
  };

  const renderToast = () => (
    <div className={`h-full ${renderColor[`bg-${opt?.type}`]}`}>
      <div className="flex text-white relative p-4">
        <div className="flex-shrink-0">
          <svg
            className={`h-8 w-8 ${renderColor[`icon-${opt?.type}`]}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            {renderIconType[opt?.type]}
          </svg>
        </div>
        <div className="ml-3">
          <div className={renderColor[`text-${opt?.type}`]}>
            <h3
              className={`text-sm font-medium ${
                renderColor[`title-${opt?.type}`]
              }`}
            >
              {opt?.title ? opt?.title : renderTitle[opt?.type]}
            </h3>
            <div className={`text-sm${options?.title ? " mt-2 " : ""} `}>
              <p>{renderContent()}</p>
            </div>
          </div>
        </div>
        <div className="absolute top-2 right-2">
          <span
            role="presentation"
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          >
            <svg
              className={`w-4 h-4 ${renderColor[`icon-${options?.type}`]}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
      <Loader type={opt?.type} timer={opt?.timeout} />
    </div>
  );

  clearTimeout(toastTimeout);

  toastTimeout = setTimeout(() => {
    close();
  }, opt.timeout + 400);

  appRoot.render(
    <div className={`z-50 fixed xs:w-72 w-80 md:w-96 m-h-20 ${opt?.position}`}>
      <div className="shadow-xl h-full w-full rounded-lg overflow-hidden toast-entrance">
        {renderToast()}
      </div>
    </div>
  );
};

export default ToastMarker;

export const toastSuccess = (content, options) =>
  toast(content, {
    ...options,
    type: "success",
  });

export const toastWarning = (content, options) =>
  toast(content, {
    ...options,
    type: "warning",
  });

export const toastInfo = (content, options) =>
  toast(content, {
    ...options,
    type: "info",
  });

export const toastError = (content, options) =>
  toast(content, {
    ...options,
    type: "error",
  });
