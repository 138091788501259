import React from 'react';

import { useNavigate } from 'react-router-dom';
import find from 'lodash/find';

import { toastError } from 'ui/toast';
import { getFirstMessage, jsUcOnlyFirst } from 'utils';

import { instance } from './_axios';

const unauthorizedAccess = (errors) =>
  errors &&
  find(
    (error) =>
      error.message && error.message.indexOf('E_UNAUTHORIZED_ACCESS') !== -1
  );

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    instance.interceptors.response.use(
      (response) => response,
      (res) => {
        // eslint-disable-next-line no-console
        // console.log('res', res, res?.code === 'ERR_NETWORK');

        if (res?.code === 'ERR_NETWORK') {
          toastError(res.message);
        }

        const { status, data, statusText } = res?.response;
        const { message, error_description, error, errors } = data;
        const dispatchStatus = {
          400: () => {
            toastError(getFirstMessage(errors));
          },
          422: () => {
            toastError(getFirstMessage(errors));
          },
          401: () => {
            const isError =
              statusText === 'Unauthorized' || unauthorizedAccess(errors);

            if (isError) {
              navigate('/logout');
              return;
            }
            toastError(message);
          },
          429: () => toastError('Too many attempts!'),
          500: () => toastError('Unable to communicate with server'),
          default: () => toastError(jsUcOnlyFirst(message)),
        };

        const err = {
          name: res?.name,
          message,
          error,
          error_description,
          status,
        };

        if (dispatchStatus[status]) {
          dispatchStatus[status]();
          throw err;
        }

        dispatchStatus.default();
        throw err;
      }
    );
  }, []);

  return children;
};

export default AxiosInterceptor;
