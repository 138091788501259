import React from 'react';

import Button from './_Button';
import EmptyState from './_EmptyState';

function NoPermission() {
  return (
    <div className="h-screen">
      <EmptyState
        label="No attach permission."
        note="Contact your administrator."
        actionButton={
          <Button
            label="Logout"
            className="btn md primary px-12"
            to="/logout"
          />
        }
      />
    </div>
  );
}
NoPermission.defaultProps = {};

NoPermission.propTypes = {};

export default NoPermission;
