import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const FormInputNumber = React.forwardRef((props, ref) => {
  const {
    name,
    icon,
    label,
    error,
    prefix,
    required,
    className,
    onChange,
    errorClassName,
    labelClassName,
    onSetFieldValue,
    containerClassName,
    ...rest
  } = props;

  const handleOnChange = (e) => {
    let v = e?.target?.value;

    // firefox issue handler
    v = v?.replace(/[a-z]|[A-Z]/g, '');

    // firefox issue handler
    if (!/^[0-9]*\.?[0-9]*$/.test(v)) {
      return;
    }

    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, v);
      return;
    }

    if (typeof onChange === 'function') {
      onChange((prev) => ({
        ...prev,
        [name]: v,
      }));
    }
  };

  const handleKeyDown = (e) => {
    const key = e?.charCode || e?.key;

    if ((key === '-' || key === 'e') && `${rest?.min}` === '0') {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="w-full relative">
        <div
          className={cn(
            'form-container transition duration-300 ease-in-out',
            containerClassName,
            {
              'form-container': !error,
              'form-container-error': error,
            }
          )}
        >
          {label && (
            <label
              className={cn('form-label', labelClassName)}
              htmlFor={rest?.id ?? name}
            >
              {label} {required ? <span className="text-red-500">*</span> : ''}
            </label>
          )}
          <div className="relative">
            {prefix && (
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <span
                  className={cn(
                    'transition duration-300 ease-in-out text-gray-400 font-light',
                    {
                      'form-input-prefix-error': error,
                      'form-input-prefix': !error,
                    }
                  )}
                >
                  {prefix}
                </span>
              </div>
            )}
            <input
              id={rest?.id ?? name}
              type="number"
              autoComplete="off"
              name={name}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              className={cn(
                'transition duration-300 ease-in-out',
                className,
                {
                  'form-input': !error,
                  'form-input-error': error,
                },
                {
                  'pl-8': prefix,
                },
                {
                  'pl-3 pr-8': icon,
                }
              )}
              {...rest}
              ref={ref}
            />
            <>
              {icon && (
                <span className="absolute right-2 h-full top-0 flex items-center">
                  {icon}
                </span>
              )}
            </>
          </div>
        </div>
        {error && (
          <small className={cn('form-error-badge', errorClassName)}>
            {error ?? ''}
          </small>
        )}
      </div>
    </div>
  );
});

FormInputNumber.defaultProps = {
  label: 'Name',
  containerClassName: '',
  labelClassName: '',
  errorClassName: '',
  className: '',
  required: false,
  type: 'text',
  error: false,
  icon: false,
  prefix: false,
  onSetFieldValue: false,
  onChange: false,
};

FormInputNumber.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  prefix: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
};

export default React.memo(FormInputNumber);
