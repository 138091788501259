import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { TbDatabaseOff } from 'react-icons/tb';

function EmptyState({
  label,
  note,
  labelSize,
  actionButton,
  containerClassName,
}) {
  return (
    <div className={cn('w-full h-full bg-white', containerClassName)}>
      <div className="flex flex-col justify-center items-center h-full space-y-3 p-10 text-center">
        <TbDatabaseOff className="w-16 h-16 text-gray-500 flex-shrink-0" />
        <div className="text-center">
          <div
            className={cn(
              'items-center mt-2 font-bold tracking-wide text-gray-500',
              labelSize
            )}
          >
            {label}
          </div>
          {note && (
            <div className="text-sm text-gray-500 w-[250px] md:w-[350px] text-center mb-4">
              {note}
            </div>
          )}
        </div>
        {actionButton && actionButton}
      </div>
    </div>
  );
}

EmptyState.defaultProps = {
  label: 'No data found.',
  note: false,
  actionButton: false,
  containerClassName: '',
  labelSize: 'text-lg md:text-xl',
};

EmptyState.propTypes = {
  labelSize: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  note: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  actionButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.instanceOf(Function),
  ]),
  containerClassName: PropTypes.string,
};

export default EmptyState;
