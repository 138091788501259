export const submitFile = async (payload, options) => {
  const {
    api,
    response = false,
    format = false,
    token = false,
    progress = () => {},
    callBack = () => {},
  } = options;
  const form = new FormData();
  form.append('file', payload);

  if (format && typeof format === 'object') {
    Object.keys(format).forEach((i) => {
      form.append(i, format[i]);
    });
  }

  // eslint-disable-next-line no-console
  console.log('form data', form, format, options);

  const xhr = new XMLHttpRequest();
  const success = await new Promise((resolve) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        progress(Math.round((event.loaded / event.total) * 100));
      }
    });
    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200);
    });

    xhr.open('POST', api, true);
    if (token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    xhr.send(form);
  });

  if (success && xhr.readyState === 4 && xhr.status === 200) {
    callBack(JSON.parse(xhr?.response));
    return;
  }

  if (xhr.status === 204) {
    callBack();
    return;
  }

  if (xhr.status >= 201 && xhr.status < 300) {
    callBack(xhr?.response);
    return;
  }

  if (response && xhr) {
    alert(xhr?.response); // eslint-disable-line
    return;
  }

  alert('Cannot upload file, check your network!'); // eslint-disable-line
};
