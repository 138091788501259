import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function PageTitle({ title, tabTitle }) {
  const t = title ? ` | ${title}` : "";

  return (
    <Helmet>
      <title>{`${tabTitle} ${t}`}</title>
    </Helmet>
  );
}

PageTitle.defaultProps = {
  tabTitle: import.meta.env.VITE_APP_TITLE,
  title: false,
};

PageTitle.propTypes = {
  title: PropTypes.string,
  tabTitle: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default PageTitle;
