import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import TabNavigation from './_TabNavigation';

function CardWithTabs({
  tabs,
  selected,
  className,
  onClick,
  children,
  containerClassName,
  overflowVisible,
}) {
  return (
    <div
      className={cn('flex-1 flex flex-col md:mt-0', {
        [containerClassName]: containerClassName,
        'md:p-5 md:-m-4': !containerClassName,
        'overflow-hidden': !overflowVisible,
      })}
    >
      <TabNavigation
        tabs={tabs}
        selected={selected}
        onClick={onClick}
        containerClassName="-mb-[1px]"
        className="rounded-b-none rounded-t-md px-4"
      />
      <div
        className={cn('card rounded-tl-none flex flex-1', {
          [className]: className,
          'overfolw-hidden': !overflowVisible,
        })}
      >
        <div className={cn("flex-1 flex flex-col", {
          'overflow-y-auto': !overflowVisible,
        })}>{children}</div>
      </div>
    </div>
  );
}
CardWithTabs.defaultProps = {
  tabs: [],
  selected: '',
  className: '',
  children: <></>,
  onClick: () => {},
  containerClassName: '',
  overflowVisible: false,
};

CardWithTabs.propTypes = {
  selected: PropTypes.string,
  className: PropTypes.string,
  tabs: PropTypes.instanceOf(Array),
  containerClassName: PropTypes.string,
  onClick: PropTypes.instanceOf(Function),
  overflowVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
};

export default CardWithTabs;
