import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { createRoot } from 'react-dom/client';

import QueryProvider from 'services';

const renderAlignment = {
  right: 'right-0',
  left: 'left-0',
};

const renderDrawerPosition = {
  right: 'translate-x-full',
  left: '-translate-x-full',
};

const renderDrawerSize = {
  'drawer-md': 'max-w-md',
  'drawer-lg': 'max-w-lg',
  'drawer-xg': 'min-w-lg max-w-screen-lg',
  'drawer-2xl': 'min-w-lg max-w-screen-lg',
};

const Drawer = React.forwardRef((props, ref) => {
  const { opt, onClose } = props;
  const [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      setAnimate(false);
      onClose();
    }
  };

  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setAnimate(false);
    onClose();
  };

  document.addEventListener('keydown', escFunction, false);
  const renderContent = () => {
    if (typeof opt.content === 'function') return opt.content(handleOnClose);
    if (typeof opt.content === 'string')
      return <div className="px-3">{opt.content}</div>;
    return 'n/a';
  };

  return (
    <div className="fixed inset-0 overflow-hidden z-40" ref={ref}>
      <div className="absolute inset-0 overflow-hidden">
        <div
          role="presentation"
          onClick={handleOnClose}
          className={cn(
            'absolute inset-0 bg-black transition-opacity bg-opacity-25 ease-in-out',
            {
              'opacity-100 duration-300': animate,
              'opacity-0 duration-200': !animate,
            }
          )}
        />
        <div
          className={cn(
            'fixed inset-y-0 max-w-full flex',
            renderAlignment[opt?.align]
          )}
        >
          <div
            className={cn(
              'relative w-screen transition duration-200',
              renderDrawerSize[opt?.drawerSize],
              {
                'translate-x-0 ease-out': animate,
                [`${renderDrawerPosition[opt?.align]} ease-in`]: !animate,
              }
            )}
          >
            <div
              className={cn(
                'flex flex-col bg-white w-full h-full py-5',
                opt?.wrapperClassName
              )}
            >
              <div
                className={cn(
                  'flex items-center px-5 ',
                  {
                    'mb-0': opt?.subTitle,
                    'mb-3': !opt?.subTitle,
                  },
                  opt?.subTitleClassName
                )}
              >
                {opt.title && (
                  <p className="text-xl text-black font-bold">{opt?.title}</p>
                )}
                {!opt.noClose && (
                  <div className="ml-auto">
                    <button
                      type="button"
                      aria-label="Close"
                      tabIndex="-1"
                      className="rounded-full transition duration-300 ease-in-out group outline-none group hover:bg-primary-500 p-1"
                      onClick={handleOnClose}
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6 transition duration-300 ease-in-out group-hover:text-white text-primary-500 rounded-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              {opt?.subTitle && (
                <p className="text-gray-500 px-5 mb-3">{opt?.title}</p>
              )}
              <div
                className={cn(
                  'flex flex-col flex-1 overflow-y-auto px-3 pb-3 md:px-5 md:pb-3 pointer-events-auto',
                  opt?.contentClassName
                )}
              >
                {opt.focusTrap ? (
                  <FocusTrap
                    focusTrapOptions={{
                      allowOutsideClick: () => true,
                    }}
                  >
                    {renderContent()}
                  </FocusTrap>
                ) : (
                  renderContent()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Drawer.propTypes = {
  opt: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

let appRoot;

export const closeDrawer = () => {
  const prevElem = document.activeElement;
  const root = document.getElementById('drawer-root');
  if (!appRoot) appRoot = createRoot(root);
  if (!root) {
    alert('drawer root not found!'); // eslint-disable-line
    return;
  }

  setTimeout(() => {
    document.body.removeAttribute('class');
    appRoot.render(null);
    setTimeout(() => {
      try {
        prevElem.blur();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, 'Modal Marker Error!!');
      }
    }, 100);
  }, 200);
};

export const showDrawer = (options) => {
  const opt = {
    title: false,
    align: 'right',
    subTitle: false,
    focusTrap: false,
    contentClassName: '',
    titleContainer: true,
    titleClassName: false,
    content: 'Drawer Body',
    onCloseCallback: false,
    drawerSize: 'drawer-md',
    wrapperClassName: '',
    ...options,
  };

  const prevElem = document.activeElement;
  document.body.className = 'overflow-hidden';
  const root = document.getElementById('drawer-root');

  if (!appRoot) appRoot = createRoot(root);

  const onClose = () => {
    try {
      if (opt.onCloseCallback) {
        opt.onCloseCallback();
      }
    } catch (err) {
      // do nothing...
    }
    setTimeout(() => {
      document.body.removeAttribute('class');
      appRoot.render(null);
      setTimeout(() => {
        try {
          prevElem.blur();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err, 'Drawer Marker Error!!');
        }
      }, 100);
    }, 200);
  };

  appRoot.render(
    <QueryProvider>
      <Drawer opt={opt} onClose={onClose} />
    </QueryProvider>
  );
};

function DrawerMarker() {
  return <div id="drawer-root" />;
}

export default React.memo(DrawerMarker);
