import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const FormInputTelephoneNumber = React.forwardRef((props, ref) => {
  const {
    type,
    name,
    label,
    error,
    icon,
    required,
    onChange,
    className,
    labelClassName,
    onSetFieldValue,
    disableDropdown,
    containerClassName,
    ...rest
  } = props;

  const handleOnChange = (e) => {
    if (typeof onSetFieldValue === "function") {
      onSetFieldValue(name, e);
      return;
    }
    if (typeof onChange === "function")
      onChange((prev) => ({
        ...prev,
        [name]: e,
      }));
  };

  return (
    <div>
      <div className="w-full relative">
        <div
          className={cn(
            "form-container transition duration-300 ease-in-out",
            containerClassName,
            {
              "form-container": !error,
              "form-container-error": error,
            }
          )}
          role="presentation"
        >
          {label && (
            <label
              className={cn("form-label", labelClassName)}
              htmlFor={rest?.id ?? name}
            >
              {label} {required ? <span className="text-red-500">*</span> : ""}
            </label>
          )}
          <div className="relative">
            <PhoneInput
              autoComplete="none"
              name={name}
              jumpCursorToEnd
              enableSearch
              country="ph"
              placeholder="+63 (9XX) XXX XXXX"
              masks={{ ph: "(.) .... ...." }}
              searchPlaceholder="Search country..."
              onChange={handleOnChange}
              disableDropdown={disableDropdown}
              inputProps={{
                id: rest?.id ?? name,
                type: "tel",
                autoComplete: "off",
                className: cn(
                  "transition duration-300 ease-in-out ",
                  className,
                  {
                    "form-input": !error,
                    "form-input-error": error,
                  },
                  {
                    "pl-3 pr-8": icon,
                  },
                  {
                    "pl-10": disableDropdown,
                    "pl-14": !disableDropdown,
                  }
                ),
              }}
              {...rest}
              ref={ref}
            />
            <>
              {icon && (
                <span className="absolute right-2 h-full top-0 flex items-center">
                  {icon}
                </span>
              )}
            </>
          </div>
        </div>
        {error && <small className=" form-error-badge">{error ?? ""}</small>}
      </div>
    </div>
  );
});

FormInputTelephoneNumber.defaultProps = {
  icon: false,
  type: "text",
  error: false,
  label: "Name",
  className: "",
  onChange: false,
  required: false,
  labelClassName: "",
  disableDropdown: true,
  onSetFieldValue: false,
  containerClassName: "",
};

FormInputTelephoneNumber.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  disableDropdown: PropTypes.bool,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
};

export default React.memo(FormInputTelephoneNumber);
