import React, { forwardRef } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const SkeletonLoader = forwardRef((props, ref) => {
  const { id, fullHeight, isLoading, color, className, children } = props;

  const cln = cn('shadow-inner rounded', color);

  return (
    <div
      ref={ref}
      className={cn({
        [cln]: isLoading,
        'h-full': fullHeight && !isLoading,
        [className]: className,
      })}
      id={id}
    >
      <div
        id={`skeleton-inner-${id}`}
        className={cn({
          invisible: isLoading,
          'h-full': fullHeight && !isLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
});

SkeletonLoader.displayName = 'Skeleton';

SkeletonLoader.defaultProps = {
  id: 'skeleton-container',
  className: '',
  children: null,
  isLoading: true,
  fullHeight: true,
  color: 'bg-gray-100',
};

SkeletonLoader.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
};

export default SkeletonLoader;
