export const INIT_REGISTER_FORM = {
  // tz: '',
  email: '',
  password: '',
  store_name: '',
  first_name: '',
  last_name: '',
  password_confirmation: '',
};

export const INIT_OTP_CONFIRM_FORM = {
  otp: '',
};

export const LOGIN_AS = {
  MERCHANT: 'merchant',
};
