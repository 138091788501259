import React from "react";

import PropTypes from "prop-types";
import cn from "classnames";

const FormInputCheckBox = React.forwardRef((props, ref) => {
  const {
    name,
    error,
    label,
    checked,
    onChange,
    className,
    withContainer,
    onSetFieldValue,
    labelClassName,
    alignmentClassName,
    containerClassName,
    labelWrapperClassName,
    ...rest
  } = props;

  const handleOnChange = () => {
    if (rest?.readOnly) return;
    if (typeof onSetFieldValue === "function") {
      onSetFieldValue(name, !checked);
      return;
    }
    if (typeof onChange === "function") {
      onChange((prev) => ({ ...prev, [name]: !checked }));
    }
  };

  return (
    <div className={cn("w-full flex flex-col", containerClassName)}>
      {withContainer && (
        <span className={cn("form-label invisible", alignmentClassName)}>
          READONLY
        </span>
      )}
      <div
        className={cn("relative transition duration-300 ease-in-out", {
          "form-control-container": withContainer && !error,
          "form-control-container-error": withContainer && error,
        })}
      >
        <label
          className={cn(
            "flex justify-start items-center",
            {
              "cursor-not-allowed": rest?.readOnly,
            },
            labelWrapperClassName
          )}
        >
          <input
            type="checkbox"
            ref={ref}
            onChange={handleOnChange}
            className={cn(
              "form-checkbox transition duration-300 ease-in-out",
              {
                "form-checkbox-error": error,
                "cursor-not-allowed": rest?.readOnly,
              },
              className
            )}
            name={name}
            checked={checked}
            {...rest}
          />
          {label && (
            <span
              id={name}
              className={cn(
                {
                  "form-control-label-error": error,
                  "form-control-label": !error,
                  "cursor-not-allowed": rest?.readOnly,
                  "!text-[#D9D9D9]": rest?.disabled,
                },
                labelClassName
              )}
            >
              {label}
            </span>
          )}
        </label>
        {error && withContainer && (
          <small className=" form-error-badge">{error ?? ""}</small>
        )}
      </div>
    </div>
  );
});

FormInputCheckBox.defaultProps = {
  className: "",
  labelClassName: "",
  label: "Check Box Name",
  withContainer: false,
  error: false,
  onChange: false,
  onSetFieldValue: false,
  alignmentClassName: "",
  containerClassName: "",
  labelWrapperClassName: "",
  checked: 0,
};

FormInputCheckBox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  className: PropTypes.string,
  labelWrapperClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  alignmentClassName: PropTypes.string,
  withContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  checked: PropTypes.bool,
};

export default FormInputCheckBox;
