import React from 'react';

import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { chain, get, orderBy } from 'lodash';

import PageLoader from './_PageLoader';
import EmptyState from './_EmptyState';
import SkeletonLoader from './_SkeletonLoader';

const checkIfValidDate = (str) => {
  // Regular expression to check if string is valid date
  const regexExp =
    /(^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$)/;

  return regexExp.test(str);
};

const groupByOrder = (data, groupName, sortBy) => {
  try {
    const groupData = chain(data)
      .groupBy((o) => {
        if (checkIfValidDate(o[groupName])) {
          const x = dayjs(o[groupName], 'YYYY-MM-DD').format('YYYY-MM-DD');
          return x || '';
        }
        return o[groupName] ? o[groupName][0].toUpperCase() : 'Undefined';
      })
      .map((value, key) => ({
        key,
        data: orderBy(value, [`${groupName}`], [`${sortBy}`]) || '',
      }))
      .value();
    const sortedData = orderBy(groupData, ['key'], [`${sortBy}`]);
    const adjustedData = sortedData.map((value, key) => {
      if (checkIfValidDate(value.key)) {
        sortedData[key].key =
          dayjs(value.key, dayjs.ISO_8601).format('ll') || '';
      }
      return value;
    });
    return adjustedData;
  } catch (error) {
    return [];
  }
};

function ItemLoading() {
  return (
    <div className="my-2 first:mt-0 cursor-wait">
      <div className="flex gap-3 px-4 py-2 rounded-md items-center w-full shadow-sm bg-gray-200 animate-pulse">
        <div className="flex-shrink-0">
          <SkeletonLoader
            className="w-12 h-12 !rounded-full"
            color="bg-gray-300/75"
          />
        </div>
        <div className="w-full">
          <SkeletonLoader className="w-full !h-4 mb-1" color="bg-gray-300/75" />
          <SkeletonLoader className="w-5/12 h-3" color="bg-gray-300/75" />
        </div>
      </div>
    </div>
  );
}

function GenericList({
  data,
  sortBy,
  nameKey,
  selected,
  labelKey,
  isLoading,
  renderRow,
  onRowClick,
  isFetching,
  selectedKey,
  itemClassName,
  groupKeyClassname,
  listContainerClassName,
}) {
  const handleRowClick = (row) => (e) => {
    e.preventDefault();
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const renderRowItem = React.useCallback(
    (row, index) => {
      if (typeof renderRow === 'function') return renderRow(row, index);
      if (typeof renderRow === 'string')
        return <span>{get(row, labelKey)}</span>;
      return <span>ROW {index + 1}</span>;
    },
    [labelKey, renderRow]
  );

  const list = React.useMemo(() => groupByOrder(data, nameKey, sortBy), [data]);

  React.useEffect(() => {
    let timer;
    if (!selected && list.length > 0 && !isLoading && !isFetching) {
      timer = setTimeout(() => {
        onRowClick(get(list, '0.data.0'));
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [JSON.stringify(list), selected]);

  React.useLayoutEffect(() => {
    if (selected) {
      const x = document.getElementById(selected);

      if (data?.length && selected && x) {
        x.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [data, selected]);

  return (
    <div className="relative w-full h-full md:min-h-0 overflow-hidden flex">
      {data?.length === 0 ? (
        <>
          {data?.length === 0 && isLoading ? (
            <div className="w-full">
              <SkeletonLoader isLoading className="h-auto">
                <div className="w-full text-xs py-px">--READ ONLY--</div>
              </SkeletonLoader>
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
              <ItemLoading />
            </div>
          ) : (
            <EmptyState />
          )}
        </>
      ) : (
        <>
          {isFetching && (
            <div className="absolute top-0 z-20 h-full w-full flex bg-white/40">
              <div className="m-auto border border-gray-50 shadow-sm rounded">
                <PageLoader
                  withBrand={false}
                  label="Please wait..."
                  className="px-2 py-1 rounded"
                />
              </div>
            </div>
          )}

          <div className="relative flex flex-col flex-1 flex-shrink-0 overflow-y-auto">
            {list.map((group) => {
              const x = group?.data ?? [];
              return (
                <div key={`group-${group?.key}`} className="relative">
                  {group?.key !== 'Undefined' && (
                    <div
                      className={cn('gl-list-key-group', {
                        [`${groupKeyClassname}`]: groupKeyClassname,
                      })}
                    >
                      <h3>{group?.key}</h3>
                    </div>
                  )}
                  <div
                    className={cn('gl-list-wrapper', {
                      [`${listContainerClassName}`]: listContainerClassName,
                    })}
                  >
                    {x.map((item, i) => {
                      const isActive =
                        `${get(item, selectedKey)}` === `${selected}`;
                      return (
                        <div
                          className={cn('gl-list-item', {
                            [`${itemClassName}`]: itemClassName,
                            'gl-list-active-item': isActive,
                          })}
                          onClick={handleRowClick(item)}
                          role="presentation"
                          key={get(item, selectedKey)}
                          id={get(item, selectedKey)}
                        >
                          {renderRowItem(item, i)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

GenericList.defaultProps = {
  data: [],
  itemClassName: '',
  nameKey: 'name',
  sortBy: 'asc',
  labelKey: 'id',
  selected: null,
  selectedKey: 'id',
  isLoading: false,
  isFetching: false,
  onRowClick: false,
  renderRow: 'label',
  groupKeyClassname: '',
  listContainerClassName: '',
};

GenericList.propTypes = {
  // list of data should be one level object
  data: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  selectedKey: PropTypes.string,
  labelKey: PropTypes.string,
  selected: PropTypes.string,
  renderRow: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Function),
    PropTypes.string,
  ]),
  onRowClick: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  groupKeyClassname: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  listContainerClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  itemClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  sortBy: PropTypes.string,
};

export default React.memo(GenericList);
