import { req } from 'services';
import { transformUser } from 'features/authentication/_transformers';

export const signUp = ({ type, payload }) =>
  req.post({
    url: `/merchants/register`,
    payload,
    transform: (res) => {
      const x = transformUser(res?.data, type);
      return x;
    },
  });

export const login = ({ type, payload }) =>
  req.post({
    url: '/merchants/auth',
    payload,
    transform: (res) => {
      const x = transformUser(res?.data, type);
      return x;
    },
  });

export const verifyOtpCode = ({ payload }) =>
  req.post({
    url: `/merchants/verify-otp`,
    payload,
    transform: (res) => res.data,
  });
