import React from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

function ErrorLabel({ error, errorClassName }) {
  return (
    <>
      <small
        className={cn(
          'flex text-xs px-4 bg-red-50 rounded text-red-500 form-error-badge relative bottom-0 right-0',
          {
            visible: error,
            invisible: !error,
          },
          errorClassName
        )}
      >
        {error || 'Error'}
      </small>
    </>
  );
}

ErrorLabel.defaultProps = {
  error: false,
  errorClassName: '',
};

ErrorLabel.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  errorClassName: PropTypes.string,
};

export default ErrorLabel;
