import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

function ColorPicker({ defaultColor, onChange, open, onClose, onOpen }) {
  const [color, setColor] = useState(defaultColor);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    if (onChange) {
      onChange(newColor.hex);
    }
  };

  return (
    <div className="relative">
      <div
        className="bg-slate-200 p-1 border-2 rounded-sm cursor-pointer inline-block"
        onClick={onOpen}
      >
        <div
          className="w-14 h-5 rounded-sm"
          style={{
            backgroundColor: `${color}`,
          }}
        />
      </div>
      {open ? (
        <div className="absolute z-2 left-20 top-0">
          <div
            className="fixed top-0 right-0 left-0 bottom-0"
            onClick={onClose}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

ColorPicker.defaultProps = {
  defaultColor: {
    r: '241',
    g: '112',
    b: '19',
    a: '1',
  },
  onClose: () => {},
  onChange: () => {},
  onOpen: () => {},
  open: false,
};

ColorPicker.propTypes = {
  defaultColor: PropTypes.shape({
    r: PropTypes.string,
    g: PropTypes.string,
    b: PropTypes.string,
    a: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default ColorPicker;
