import React from 'react';

import PropTypes from 'prop-types';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient, queryCache } from './_config';

function QueryProvider({ children, withDevTool }) {
  return (
    <QueryClientProvider client={queryClient} queryCache={queryCache}>
      {children}
      {withDevTool && (
        <ReactQueryDevtools initialIsOpen={false} panelPosition="left" />
      )}
    </QueryClientProvider>
  );
}
QueryProvider.defaultProps = {
  withDevTool: false,
};

QueryProvider.propTypes = {
  withDevTool: PropTypes.bool,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default QueryProvider;
