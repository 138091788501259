import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import Brand from "./_Brand";

function PageLoader({ className, withBrand, label }) {
  return (
    <div
      className={cn(
        "w-full h-full pointer-events-none bg-white flex",
        className
      )}
    >
      <div className="m-auto grayscale invert opacity-30">
        <div className="m-auto text-center space-y-2">
          {withBrand && (
            <div className="flex justify-center">
              <Brand className="h-6" type="wide" url={false} />
            </div>
          )}
          <div className="flex items-center space-x-2">
            <svg
              className="animate-spin h-4 w-4 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            <h4 className="font-medium text-sm text-gray-200">{label || "Loading..."}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

PageLoader.defaultProps = {
  className: "",
  withBrand: true,
  label: "Loading..."
};

PageLoader.propTypes = {
  withBrand: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
};

export default PageLoader;
