import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { Brand } from 'ui/components';
import { ROOT_SESSION } from 'context/session';
import { req } from 'services';
import { storage } from 'utils';

import { useLogout } from '../../logout/_hooks';
import LoginForm from '../forms/LoginForm';
import RegisterForm from '../forms/RegisterForm';

function ShopifyLandingPage() {
  const { isAuthenticated, setIfAuthenticated } =
    React.useContext(ROOT_SESSION);
  const [logout] = useLogout();
  const {search} = window.location;
  const params = new URLSearchParams(search);
  const token = params.get("token")
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      logout(() => {
        setIfAuthenticated(false);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(token) {
      storage.set("_token", token)
      const getProfile = () =>
        req.get({
          url: '/merchants/my/profile',
        });

      getProfile().then((user) => {
        storage.set('profile', {
          full_name: user?.full_name,
          email: user?.email,
          user_type: 'merchant',
        });
        storage.set('permissions', user.permissions);
        storage.set(
          '_user_key',
          `${user?.id}-${new Date(user?.updated_at).getTime()}`
        );
        setIfAuthenticated(true)
        navigate("/")
      });
    }
  }, [navigate])

  return (
    <div className="flex flex-col w-screen h-screen bg-primary-500">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-5 flex-1">
        <div className="hidden md:block 2xl:col-span-3">
          <div className="flex h-full">
            <div className="absolute p-20">
              <Brand url="https://galanta.com" className="h-16" type="wide" />
            </div>
            <div className="card shadow-primary-500 md:p-10 w-3/5 m-auto my-auto">
              <LoginForm />
            </div>
            <div className="absolute bottom-0 w-full">
              <h4 className="mx-auto text-white font-normal text-sm text-center py-5">
                © Galanta | All Rights Reserved {dayjs().format('YYYY')}
              </h4>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-2 relative">
          <div className="bg-white px-10 flex h-full">
            <div className="m-auto my-auto w-full xl:w-8/12">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ShopifyLandingPage.defaultProps = {};

ShopifyLandingPage.propTypes = {};

export default ShopifyLandingPage;
