import React from 'react';

import PropTypes from 'prop-types';
import { CgSpinner } from 'react-icons/cg';

import SkeletonLoader from './_SkeletonLoader';

function Pagination({ onChange, value, isFetching, disabled, isLoading }) {
  const prevElem = document.activeElement;

  const handlePrev = () => {
    const newPage = value.current_page - 1;
    if (newPage <= 0) return;
    prevElem.blur();
    onChange(newPage);
  };

  const handleNext = () => {
    const newPage = value.current_page + 1;
    if (newPage > value.last_page) return;
    prevElem.blur();
    onChange(newPage);
  };

  return (
    <div className="flex justify-between items-center">
      <SkeletonLoader isLoading={isLoading} className="h-auto flex">
        <div className="text-gray-500 font-medium tracking-wide text-xs h-full flex items-center">
          {value.total || 0} item(s)
        </div>
      </SkeletonLoader>
      <div className="flex items-center gap-2">
        {(isFetching || disabled) && (
          <div className="md:hidden">
            <CgSpinner className="h-5 w-5 animate-spin" />
          </div>
        )}
        <SkeletonLoader isLoading={isLoading}>
          <div className="flex items-center border border-gray-400 bg-white text-gray-400 rounded">
            <button
              className="p-2 hover:bg-gray-500 hover:text-white transition duration-300 ease-in-out"
              type="button"
              aria-label="Previous"
              onClick={handlePrev}
              disabled={disabled}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={3}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <div className="border-r px-2 min-w-[48px] text-center border-l border-gray-400 h-full py-1">
              <span className="text-gray-500 font-base tracking-wide text-sm">
                {value.current_page || 1}/{value.last_page || 1}
              </span>
            </div>
            <button
              className="p-2 hover:bg-gray-500 hover:text-white transition duration-300 ease-in-out"
              type="button"
              aria-label="Next"
              onClick={handleNext}
              disabled={disabled}
            >
              <svg
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={3}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </SkeletonLoader>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  value: PropTypes.instanceOf(Object),
};

Pagination.defaultProps = {
  onChange: () => {},
  value: {
    page: 1,
    total: 1,
    last_page: 1,
    current_page: 1,
  },
  disabled: false,
  isLoading: false,
  isFetching: false,
};

export default Pagination;
