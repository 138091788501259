import React from "react";

import PropTypes from "prop-types";

import ThemePageHeader from "layouts/page-theme/_ThemePageHeader";
import ThemePageFooter from "layouts/page-theme/_ThemePageFooter";

function ThemePageLayout({ children }) {
  return (
    <div className="h-full w-full theme-root">
      <ThemePageHeader />
      <div className="theme-root-main bg-opacity-100">
        <main>{children}</main>
      </div>
      <ThemePageFooter />
    </div>
  );
}

ThemePageLayout.defaultProps = {
  children: <></>
};

ThemePageLayout.propTypes = {
  children: PropTypes.instanceOf(Object)
};

export default ThemePageLayout;
