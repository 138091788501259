import React from 'react';

import cn from 'classnames';
import { each } from 'lodash';
import PropTypes from 'prop-types';

import Button from './_Button';

// Parent div should have relative className

export const setError422 = (err, setFieldError) => {
  if (err?.status === 422) {
    each(err?.response?.errors, (v, k) => {
      if (v[0]) setFieldError(k, v[0]);
    });
  }
};

const UnSavedAlert = React.forwardRef((props, ref) => {
  const { type, label, isLoading, hasChange, onCancel, onSubmit } = props;

  return (
    <div
      ref={ref}
      className={cn(
        'px-5 py-3 bg-black/50',
        'fixed md:absolute bottom-0 left-0',
        'flex items-center w-full',
        'transform duration-200',
        'z-30',
        {
          'translate-y-0 ease-out': hasChange,
          'translate-y-full ease-in': !hasChange,
        }
      )}
    >
      <h4 className="text-white font-semibold text-sm md:text-base">{label}</h4>
      <div className="xs:mx-auto ml-auto flex gap-3">
        <Button
          color="white"
          label="Discard"
          onClick={onCancel}
          disabled={isLoading}
          className="xs:w-auto w-28"
        />
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={isLoading}
          isLoading={isLoading}
          className="xs:w-auto w-28"
          label={type === 'CREATE' ? 'Create' : 'Update'}
        />
      </div>
    </div>
  );
});

UnSavedAlert.defaultProps = {
  type: 'CREATE',
  isLoading: false,
  hasChange: false,
  label: 'You have unsaved changes',
};

UnSavedAlert.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  hasChange: PropTypes.bool,
  onCancel: PropTypes.instanceOf(Function).isRequired,
  onSubmit: PropTypes.instanceOf(Function).isRequired,
};

export default UnSavedAlert;
