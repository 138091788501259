export const LOGIN_AS = {
  ADMIN: 'admin',
  MERCHANT: 'merchant',
};

export const ALLOWED_CODE = [
  LOGIN_AS.ADMIN,
  LOGIN_AS.MERCHANT,
  // 'home',
  // 'account-settings',
  // 'customers',
  // 'communications',
  // 'my-club',
];
