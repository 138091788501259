import * as yup from 'yup';

export const signUpSchema = yup.object({
  // tz: yup.object().required('Timezone is required.').nullable(),
  email: yup.string().required('Email is required.').email(),
  store_name: yup.string().required('Store name is required.'),
  domain_prefix: yup.string().required('Store URL is required.'),
  first_name: yup.string().required('First name is required.'),
});

export const loginSchema = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('email is required.'),
  password: yup.string().required('Password is required.'),
});
