import {
  QueryCache,
  QueryClient,
  useMutation as $useMutation,
  useQuery as $useQuery,
} from '@tanstack/react-query';

export const END_POINT = import.meta.env.VITE_APP_END_POINT;
export const UPLOADER_ENDPOINT = `${END_POINT}/merchants/upload`;
export const UPLOADER_ADMIN_ENDPOINT = `${END_POINT}/admin/upload`;
export const TOKEN_KEY = import.meta.env.VITE_APP_TOKEN_KEY;

export const APP_RECAPTCHA_KEY = import.meta.env.VITE_APP_RECAPTCHA_KEY;
export const useQuery = (queryKey, queryFn, options) =>
  $useQuery({ queryKey, queryFn, ...options });
export const useMutation = (mutationFn, options) => {
  const {
    data,
    error,
    isError,
    isIdle,
    isPaused,
    isPending,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = $useMutation({ mutationFn, ...options });
  return {
    data,
    error,
    mutate,
    mutateAsync,
    reset,
    status,
    isSuccess,
    isError,
    isIdle,
    isPaused,
    isPending,
    isLoading: isPending,
  };
};

export const queryCache = new QueryCache();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
