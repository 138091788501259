import axios from 'axios';

import { removeEmpty, storage } from 'utils';

import { END_POINT, TOKEN_KEY } from '../store';

export const instance = axios.create({
  baseURL: END_POINT,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 180000,
  validateStatus: (status) => status >= 200 && status < 300,
});

instance.interceptors.request.use((config) => {
  const token = storage.get(TOKEN_KEY);
  if (token) {
    return {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return config;
});

const post = async ({ url, payload, transform = false }, axiosConfig) => {
  const res = await instance
    .post(url, payload, axiosConfig)
    .then((response) => response)
    .catch((response) => response);

  if (res?.name === 'AxiosError') {
    throw res;
  }
  if (typeof transform === 'function') {
    const x = transform(res);
    return x;
  }

  return res?.data;
};

const get = async ({ url, params, transform = false }, axiosConfig) => {
  const res = await instance
    .get(url, {
      ...axiosConfig,
      ...(params
        ? {
            params: removeEmpty(params),
          }
        : {}),
    })
    .then((response) => response?.data)
    .catch((response) => response);

  if (res?.name === 'AxiosError') {
    throw res;
  }

  if (typeof transform === 'function') {
    const x = transform(res);
    return x;
  }
  return res?.data;
};

const put = async ({ url, payload, transform = false }, axiosConfig) => {
  const res = await instance
    .put(url, payload, axiosConfig)
    .then((response) => response)
    .catch((response) => response);

  if (res?.name === 'AxiosError') {
    throw res;
  }

  if (typeof transform === 'function') {
    const x = transform(res);
    return x;
  }

  return res?.data;
};

const patch = async ({ url, params, transform = false }, axiosConfig) => {
  const res = await instance
    .patch(url, params, axiosConfig)
    .then((response) => response)
    .catch((response) => response);
  if (res?.name === 'AxiosError') {
    throw res;
  }
  if (typeof transform === 'function') {
    const x = transform(res);
    return x;
  }

  return res?.data;
};

const remove = async ({ url, payload, transform = false }, axiosConfig) => {
  const res = await instance
    .delete(url, payload, axiosConfig)
    .then((response) => response)
    .catch((response) => response);
  if (res?.name === 'AxiosError') {
    throw res;
  }
  if (typeof transform === 'function') {
    const x = transform(res);
    return x;
  }

  return res?.data;
};

export { post, get, put, patch, remove };
