import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from './_Button';

function TabNavigation({
  type,
  tabs,
  onClick,
  selected,
  className,
  containerClassName,
}) {
  const handleOnClick = (i) => (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(i);
    }
  };

  React.useEffect(() => {
    const x = setTimeout(() => {
      if (tabs.length > 0 && !selected) {
        onClick(tabs[0]);
      }
    }, 300);

    return () => clearTimeout(x);
  }, [tabs, !selected]);

  return (
    <div
      className={cn(
        'flex',
        {
          'gap-3 px-1 border-b border-gray-200': type === 'LINK',
          'gap-1': type === 'BUTTON',
        },
        {
          [containerClassName]: containerClassName,
        }
      )}
    >
      {tabs?.length > 0
        ? tabs?.map((i, k) => {
            const { name, label, key, value } = i;
            const $key = k;
            const isActive = (key || value) === selected;
            if (type === 'LINK') {
              return (
                <Button
                  key={$key}
                  label={name || label}
                  className={cn(
                    'py-2 md:py-2.5 text-sm font-medium flex-shrink-0',
                    'border-b-[3px] border-primary-400',
                    'ease-out-in transition duration-300',
                    {
                      'text-primary-500': isActive,
                      'text-gray-500 border-transparent hover:border-primary-500 hover:text-primary-500':
                        !isActive,
                    },
                    { [className]: className }
                  )}
                  onClick={handleOnClick(i)}
                />
              );
            }
            return (
              <Button
                key={$key}
                label={name || label}
                className={cn(
                  'btn flex-shrink-0',
                  {
                    primary: isActive,
                    light: !isActive,
                  },
                  { [className]: className, md: !className }
                )}
                onClick={handleOnClick(i)}
              />
            );
          })
        : null}
    </div>
  );
}
TabNavigation.defaultProps = {
  tabs: [],
  selected: '',
  type: 'BUTTON',
  onClick: false,
  className: false,
  containerClassName: false,
};

TabNavigation.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  containerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tabs: PropTypes.instanceOf(Array),
  onClick: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(TabNavigation);
