import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { RiAttachment2 } from 'react-icons/ri';

function FormInputUploader({
  name,
  error,
  label,
  value,
  required,
  onChange,
  className,
  placeholder,
  labelClassName,
  errorClassName,
  onSetFieldValue,
  containerClassName,
  ...rest
}) {
  const handleOnUpload = (e) => {
    const x = e?.target?.files[0];
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, x);
      return;
    }
    if (typeof onChange === 'function')
      onChange((prev) => ({
        ...prev,
        [name]: x,
      }));
  };

  return (
    <div>
      <div className="w-full relative">
        <label
          htmlFor={rest?.id ?? name}
          className={cn(
            'form-container transition duration-300 ease-in-out',
            containerClassName,
            {
              'form-container': !error,
              'form-container-error': error,
            }
          )}
        >
          <div className={cn('form-label', labelClassName)}>
            {label && (
              <>
                {label}{' '}
                {required ? <span className="text-red-500">*</span> : ''}
              </>
            )}
          </div>
          <div
            className={cn(
              'cursor-pointer max-h-[42px] transition duration-300 ease-in-out',
              className,
              {
                'form-input-file ': !error,
                'form-input-error': error,
              }
            )}
          >
            <div className="flex justify-between w-full items-center">
              <div className="flex gap-1 items-center">
                <RiAttachment2
                  className={cn('w-5 h-5 transition duration-300 ease-in-out', {
                    'text-primary-500': !error,
                    'text-red-600': error,
                  })}
                />
                <span>
                  {value ? value?.name || 'No choosen file' : placeholder}
                </span>
              </div>
              <div
                className={cn(
                  'py-1 px-3 font-medium text-sm transition duration-300 ease-in-out',
                  {
                    'text-primary-500': !error,
                    'text-red-600': error,
                  }
                )}
              >
                Upload
              </div>
            </div>
            <input
              type="file"
              id={name}
              name={name}
              onChange={handleOnUpload}
              className="hidden"
            />
          </div>
        </label>
        {error && (
          <small className={cn('form-error-badge', errorClassName)}>
            {error ?? ''}
          </small>
        )}
      </div>
    </div>
  );
}

FormInputUploader.defaultProps = {
  value: false,
  error: false,
  label: 'Name',
  className: '',
  onChange: false,
  required: false,
  labelClassName: '',
  errorClassName: '',
  containerClassName: '',
  onSetFieldValue: false,
  placeholder: 'Select File',
};

FormInputUploader.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  labelClassName: PropTypes.string,
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
};

export default FormInputUploader;
