export const transformUser = (item, type) => {
  const { user, permissions } = item;
  const x = {
    ...item,
    user: {
      ...user,
      full_name: `${user?.first_name} ${user?.last_name}`,
    },
    permissions:
      type === 'merchant'
        ? [...permissions, 'static-access']
        : ['static-access'],
  };

  return x;
};
