import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const renderColor = {
  success: 'bg-green-100 text-green-800',
  danger: 'bg-red-100 text-red-800',
  warning: 'bg-yellow-100 text-yellow-800',
  info: 'bg-blue-100 text-blue-800',
  secondary: 'bg-secondary-500 text-white',
  primary: 'bg-primary-500 text-white',
  gray: 'bg-gray-400 text-white',
  light: 'bg-gray-200 text-gray-600',
  white: 'bg-gray-50 text-gray-600',
  orange: 'bg-orange-100 text-orange-800',
};

const badgeSize = {
  sm: 'px-1.5 py-px text-xxs',
  md: 'px-2.5 py-0.5 text-xs',
};

function Badge({ label, className, color, size, ...rest }) {
  return (
    <div
      className={cn(
        'flex-wrap text-center font-semibold rounded-full inline-block',
        [renderColor[color]],
        {
          [`${className}`]: className,
        },
        badgeSize?.[size]
      )}
      {...rest}
    >
      {label}
    </div>
  );
}

Badge.defaultProps = {
  size: 'md',
  label: 'Title',
  color: 'success',
  className: false,
};

Badge.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Badge;
