import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FaRegEye } from 'react-icons/fa';
import { BsFillFileEarmarkFill } from 'react-icons/bs';

import Preview from './Preview';
import { ROOT_ACTION } from '../_constants';
import UploadFileSection from '../sections/UploadFileSection';

const buttonClassName = {
  buttonClass:
    'px-5 py-3 w-full ease-in-out transition duration-300 text-left flex gap-3 text-gray-700 items-center',
  labelClass: 'font-semibold text-base',
  iconClass: 'h-5 w-5',
};

const BUTTON_LIST = [
  {
    icon: FaRegEye,
    label: 'Preview',
    id: 'preview',
  },
  {
    icon: BsFillFileEarmarkFill,
    label: 'Local File',
    id: 'local-file',
  },
];

function Layout({ options, onClose, instructions }) {
  const [selected, setSelected] = React.useState('local-file');
  const [file, setFile] = React.useState();

  const handleOnClick = (v) => (e) => {
    e.preventDefault();
    setSelected(v);
  };

  const dispatchSection = {
      preview: <Preview />,
        'local-file': <UploadFileSection instructions={instructions} />,
    };

  const $BUTTON_LIST = React.useMemo(() => {
    if (!file) {
      return BUTTON_LIST?.filter((i) => i?.id !== 'preview');
    }
    return BUTTON_LIST;
  }, [file]);

  const x = React.useMemo(
    () => ({ onClose, file, setFile, setSelected, options }),
    [file]
  );

  React.useEffect(
    () =>
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      () => {
        if (file && file.src) {
          URL.revokeObjectURL(file.src);
        }
      },
    [file]
  );

  return (
    <ROOT_ACTION.Provider value={x}>
      <div className="flex h-[700px]">
        <div className="w-56 bg-gray-100 mr-auto flex-shrink-0">
          <div className="w-56 bg-gray-100 mr-auto flex-shrink-0">
            {$BUTTON_LIST?.map((i) => {
              const { id, label, icon } = i;
              const active = id === selected;
              return (
                <button
                  key={id}
                  type="button"
                  className={cn(buttonClassName?.buttonClass, {
                    'bg-white': active,
                    'hover:bg-gray-100': !active,
                  })}
                  onClick={handleOnClick(id)}
                >
                  {icon && <i.icon className={buttonClassName?.iconClass} />}
                  <p className={buttonClassName?.labelClass}>{label}</p>
                </button>
              );
            })}
          </div>
        </div>
        <div className="px-5 pb-5 w-full flex flex-col">
          {dispatchSection[selected]}
        </div>
      </div>
    </ROOT_ACTION.Provider>
  );
}
Layout.defaultProps = {};

Layout.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  instructions: PropTypes.instanceOf(String).isRequired
};

export default Layout;
