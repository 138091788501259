import { get } from 'lodash';

import { jsUcOnlyFirst } from 'utils/string-formaters';

export const getFirstMessage = (data) => {
  if (!data) return '';
  let firstMessage = '';
  let x = 0;

  Object.keys(data).map((i) => {
    if (x === 0) {
      firstMessage =
        get(data[i], 'details') ||
        get(data[i], 'detail') ||
        get(data[i], 'message') ||
        get(data[i], '0');
    }

    x += 1;
    return x;
  });

  return Array.isArray(firstMessage)
    ? jsUcOnlyFirst(firstMessage[0])
    : jsUcOnlyFirst(firstMessage);
};
