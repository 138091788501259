const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    localStorage.setItem(key, newValue);
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};

export default storage;
