import React from "react";

import PropTypes from "prop-types";
import cn from "classnames";

const FormInputRadioBox = React.forwardRef((props, ref) => {
  const {
    name,
    error,
    label,
    checked,
    onChange,
    className,
    withContainer,
    labelClassName,
    onSetFieldValue,
    alignmentClassName,
    ...rest
  } = props;

  const handleOnChange = () => {
    if (typeof onSetFieldValue === "function") {
      onSetFieldValue(name, rest?.value || !checked);
      return;
    }
    if (typeof onChange === "function")
      onChange((prev) => ({ ...prev, [name]: rest?.value || !checked }));
  };

  return (
    <div className="w-full flex flex-col">
      {withContainer && (
        <span className={cn("form-label invisible", alignmentClassName)}>
          READONLY
        </span>
      )}
      <div
        className={cn(
          "relative transition duration-300 ease-in-out",
          withContainer,
          {
            "form-control-container": withContainer && !error,
            "form-control-container-error": withContainer && error,
          },
          {
            "cursor-not-allowed": rest?.disabled,
          }
        )}
      >
        <label className="flex justify-start items-center">
          <input
            type="radio"
            ref={ref}
            onChange={handleOnChange}
            className={cn(
              "form-radio transition duration-300 ease-in-out",
              {
                "form-radio-error": error,
              },
              className
            )}
            name={name}
            checked={checked}
            {...rest}
          />
          {label && (
            <div
              id={name}
              className={cn(
                {
                  "form-control-label-error": error,
                  "form-control-label": !error,
                },
                labelClassName
              )}
            >
              {label}
            </div>
          )}
        </label>
        {error && withContainer && (
          <small className=" form-error-badge">{error ?? ""}</small>
        )}
      </div>
    </div>
  );
});

FormInputRadioBox.defaultProps = {
  className: "",
  labelClassName: "",
  label: "Check Box Name",
  withContainer: false,
  error: false,
  onChange: false,
  onSetFieldValue: false,
  checked: 0,
  alignmentClassName: "",
};

FormInputRadioBox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  withContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  checked: PropTypes.bool,
  alignmentClassName: PropTypes.string,
};

export default FormInputRadioBox;
