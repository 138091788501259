import React from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { FormInput } from 'ui/forms';
import { Button } from 'ui/components';
import { ROOT_SESSION } from 'context/session';

import { LOGIN_AS } from '../_constants';
import { useLogin } from '../_hooks';
import { loginSchema } from '../_validations';

const INIT_FORM = {
  email: '',
  password: '',
};

const renderGreeting = {
  merchant: 'Login to Merchant Portal',
};

function LoginForm() {
  const navigate = useNavigate();

  const { setIfAuthenticated } = React.useContext(ROOT_SESSION);

  const [validation, setValidation] = React.useState(false);

  const [isApiLoading, login] = useLogin();

  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues: INIT_FORM,
    validationSchema: loginSchema,
    validateOnChange: validation,
    validateOnBlur: validation,
    onSubmit: (form) => {
      // eslint-disable-next-line no-console
      // console.log('form', form);

      login(LOGIN_AS.MERCHANT, form, () => {
        setIfAuthenticated(true);
        navigate(
          '/account-settings/integrations/shopify-pos'
        );
      });
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
        setValidation(true);
      }}
      noValidate
      autoComplete="off"
    >
      <div className="space-y-4">
        <h4 className="text-base font-bold text-center">
          {renderGreeting[LOGIN_AS.MERCHANT]}
        </h4>
        <div className="space-y-5">
          <FormInput
            id="login_email"
            name="email"
            label="Email"
            type="email"
            placeholder="Email address"
            autoFocus
            noautofill="true"
            value={values?.email || ''}
            onSetFieldValue={setFieldValue}
            error={errors?.email}
            required
          />
          <div>
            <FormInput
              id="login_password"
              label="Password"
              name="password"
              placeholder="Password"
              value={values?.password || ''}
              type="password"
              autoComplete="none"
              onSetFieldValue={setFieldValue}
              withShowPassword
              error={errors?.password}
              required
            />
            <div className="mt-2 flex">
              <Button
                size="sm"
                className="font-medium ml-auto"
                label="Forgot password?"
                to="/forgot-password"
              />
            </div>
          </div>
        </div>
        <div className="text-center space-y-2">
          <Button
            withLoader
            className="w-full py-3"
            id="login"
            type="submit"
            label='Login'
            color='primary'
            disabled={isApiLoading}
            isLoading={isApiLoading}
          />
        </div>
      </div>
    </form>
  );
}
LoginForm.defaultProps = {};

LoginForm.propTypes = {};

export default LoginForm;
