import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { ImCross } from 'react-icons/im';
import { FaSearch } from 'react-icons/fa';

const FormInputSearch = React.forwardRef((props, ref) => {
  const { name, className, withClearButton, onChange, ...rest } = props;

  const handleOnChange = (e) => {
    onChange((prev) => ({
      ...prev,
      [name]: e?.target.value,
    }));
  };

  const handleOnClear = (e) => {
    e.preventDefault();
    onChange((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  return (
    <div className="w-full flex min-w-0">
      <label htmlFor="search" className="sr-only">
        {rest?.label ?? 'Search'}
      </label>
      <div className="relative overflow-hindden w-full transition duration-300 ease-in-out text-form-inactive">
        <input
          type="text"
          ref={ref}
          name={name}
          id={rest?.rest ?? name}
          className={cn(
            'form-input transition duration-300 ease-in-out pl-10',
            withClearButton && rest?.value ? 'pr-10' : 'pr-3',
            className
          )}
          placeholder={rest?.placeholder ?? 'Search'}
          onChange={handleOnChange}
          {...rest}
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
          <FaSearch className=" h-5 w-5" />
        </div>
        {withClearButton && rest?.value && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <button
              id={name}
              type="button"
              aria-label="Clear"
              className="h-full"
              title="Clear"
              onClick={handleOnClear}
            >
              <ImCross className="text-gray-500 hover:text-gray-500 h-3 w-3" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

FormInputSearch.defaultProps = {
  className: '',
  onChange: () => {},
  withClearButton: false,
};

FormInputSearch.propTypes = {
  className: PropTypes.string,
  withClearButton: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function),
};

export default FormInputSearch;
