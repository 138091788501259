import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/order
import { useFormik } from 'formik';

import { useMatch, useNavigate } from 'react-router-dom';

import { FormInput } from 'ui/forms';
import { Button } from 'ui/components';
import { ROOT_SESSION } from 'context/session';
import { LOGIN_AS } from 'features/authentication/_constants';

import { useSignUp } from '../_hooks';
import { signUpSchema } from '../_validations';
import { INIT_REGISTER_FORM } from '../_constants';

function RegisterForm() {

  const navigate = useNavigate();

  const [step] = useState(1);
  const { setIfAuthenticated } = React.useContext(ROOT_SESSION);
  const [registerValidation, setRequesterValidation] = useState(false);
  const parentMatch = useMatch(`/:auth_code/*`);
  const { auth_code = LOGIN_AS.MERCHANT } = parentMatch?.params || {};
  const [isApiLoading, signup] = useSignUp();
  const params = new URLSearchParams(window.location.search)
  const shopifyDomain = params.get('shopifyDomain') ?? '';

  const {
    handleSubmit: handleRegister,
    setFieldValue,
    values,
    errors,
  } = useFormik({
    initialValues: { ...INIT_REGISTER_FORM, domainPrefix: shopifyDomain ?? '' },
    validationSchema: signUpSchema,
    validateOnChange: registerValidation,
    validateOnBlur: registerValidation,
    onSubmit: (form) => {
      signup(auth_code, form, () => {
        setIfAuthenticated(true);
        navigate('/');
      });
    },
  });

  useEffect(() => {
    if (shopifyDomain.length > 0) {
      setFieldValue('domain_prefix', `https://${  shopifyDomain}`)
      setFieldValue('store_name', shopifyDomain.split('.')[0])
    }
  }, []);

  return (
    <>
      {step === 1 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRegister();
            setRequesterValidation(true);
          }}
          noValidate
          autoComplete="off"
        >
          <h4 className="text-lg lg:text-xl 2xl:text-3xl font-bold">
            Create an Account
          </h4>
          <div className="mt-5 space-y-7">
            <div className="space-y-4">
              <FormInput
                id="signup_store_name"
                name="store_name"
                label="Store Name"
                autoFocus
                noautofill="true"
                value={values?.store_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.store_name}
                required
                placeholder="Your Store Name"
              />
              <FormInput
                id="signup_domain_name"
                name="domain_prefix"
                label="Store Domain URL"
                autoFocus
                placeholder="Ex. https://<your-store-name>.myshopify.com"
                noautofill="true"
                value={values?.domain_prefix || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.domain_prefix || ''}
                required
              />
              <FormInput
                id="signup_first_name"
                name="first_name"
                label="First Name"
                noautofill="true"
                value={values?.first_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.first_name}
                placeholder="John"
                required
              />
              <FormInput
                id="signup_last_name"
                name="last_name"
                label="Last Name"
                noautofill="true"
                placeholder="Doe"
                value={values?.last_name || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.last_name}
                required
              />
              <FormInput
                id="signup_email"
                name="email"
                label="Email"
                type="email"
                placeholder="Ex. your@email.com"
                noautofill="true"
                value={values?.email || ''}
                onSetFieldValue={setFieldValue}
                error={errors?.email}
                required
              />
            </div>
            <Button
              withLoader
              className="w-full py-3"
              id="submit"
              type="submit"
              label="Submit"
              color="primary"
              disabled={isApiLoading}
              isLoading={isApiLoading}
            />
          </div>
        </form>
      )}
      {step === 2 && (
        <h4 className="text-base 2xl:text-xl">
          <p>
            Thank you! Your account has been verified successfully! Please login
            with your account from the left side.
          </p>
        </h4>
      )}
    </>
  );
}

RegisterForm.defaultProps = {};

RegisterForm.propTypes = {};

export default RegisterForm;
