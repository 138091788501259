import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import Layout from '../conatiners/Layout';

import 'react-advanced-cropper/dist/style.css';

function UploaderModal({ options, onClose, instructions }) {
  const [animate, setAnimate] = React.useState(false);

  const handleOnClose = (e) => {
    if (e) e.preventDefault();
    setAnimate(false);
    onClose();
  };

  const escFunction = (e) => {
    if (e.keyCode === 27) {
      setAnimate(false);
      onClose();
    }
  };

  React.useEffect(() => {
    setAnimate(true);
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction);
  }, []);

  return (
    <div className="relative z-40">
      <div
        className={cn(
          'fixed inset-0 bg-black bg-opacity-30 transition-opacity',
          {
            'opacity-100 ease-out duration-300': animate,
            'opacity-0 ease-in duration-200': !animate,
          }
        )}
      />
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full text-center">
          <div
            className={cn(
              'relative bg-white rounded-md text-left w-full shadow-xl transform transition-all max-w-screen-lg overflow-hidden',
              {
                'opacity-100 ease-out duration-300 translate-y-4 sm:scale-100':
                  animate,
                'opacity-0 ease-in duration-200 translate-y-0 sm:scale-90':
                  !animate,
              }
            )}
          >
            <Layout onClose={handleOnClose} options={options} instructions={instructions} />
          </div>
        </div>
      </div>
    </div>
  );
}

UploaderModal.defaultProps = {};

UploaderModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  instructions: PropTypes.instanceOf(String).isRequired,
};

export default UploaderModal;
