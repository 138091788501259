import React from 'react';

import {
  ImFilePdf,
  ImFileWord,
  ImFileText2,
  ImFilesEmpty,
} from 'react-icons/im';
import { CgSpinner } from 'react-icons/cg';
import noop from 'lodash/noop';

import { submitFile } from '../_actions';
import { ROOT_ACTION } from '../_constants';
import CloseButton from '../components/CloseButton';
import SectionTitle from '../components/SectionTitle';

const formatBytes = (v) => {
  let bytes = v;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  for (let i = 0; i < units.length; i += 1) {
    if (bytes <= 1024) {
      return `${bytes} ${units[i]}`;
    }
    bytes = parseFloat(bytes / 1024).toFixed(2);
  }
  return `${bytes} P`;
};

const dispatchFileIcon = {
  default: ImFileText2,
  'text/csv': ImFilesEmpty,
  'application/pdf': ImFilePdf,
  'application/password': ImFileWord,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
   ImFileWord,
};

function FileSection() {
  const { onClose, file, options } = React.useContext(ROOT_ACTION);
  const [loading, setLoading] = React.useState(false);

  const handleOnUpload = async (e) => {
    e.preventDefault();

    if (options.prehook && typeof options.prehook === 'function') {
      options.prehook(options, file, async (opt) => {
        setLoading(true);
        const args = file?.original_file;
        await submitFile(args, {
          ...options,
          ...opt,
        });
        setLoading(false);
        onClose();
      })
    } else {
      setLoading(true);
      const args = file?.original_file;
      await submitFile(args, options);
      setLoading(false);
      onClose();
    }
  };

  const { type, file_name, file_size } = file;

  const Icon = dispatchFileIcon[type] || dispatchFileIcon?.default;

  return (
    <>
      <div className="flex items-center">
        <SectionTitle title="File Details" />
        <CloseButton loading={loading} />
      </div>
      <div className="flex-1 flex">
        <div className="m-auto space-y-4">
          <div className="flex">
            <Icon className="w-28 h-28 text-gray-600 mx-auto" />
          </div>
          <div className="text-center font-medium text-gray-700">
            <h4 className="text-xl">{file_name}</h4>
            <span className="text-base">{formatBytes(file_size)}</span>
          </div>
        </div>
      </div>
      <div className="w-flex flex-col space-y-3">
        <div className="flex-1 flex justify-between items-center">
          <button
            type="button"
            disabled={loading}
            className="rounded w-28 py-2 text-gray-700 border border-bg-gray-700 hover:bg-gray-600 hover:text-white transition duration-200 ease-in-out"
            onClick={loading ? noop : onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={loading}
            className="rounded w-28 py-2 text-white bg-gray-700 hover:bg-gray-500 transition duration-200 ease-in-out"
            onClick={loading ? noop : handleOnUpload}
          >
            {loading ? (
              <div className="flex items-center justify-center space-x-2">
                <CgSpinner className="h-5 w-5 animate-spin" />
              </div>
            ) : (
              'Done'
            )}
          </button>
        </div>
      </div>
    </>
  );
}
FileSection.defaultProps = {};

FileSection.propTypes = {};

export default FileSection;
