import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo.svg';
import logoWide from 'assets/images/logo-wide.svg';

function Brand({ className, type, url }) {
  const $logo = type === 'small' ? logo : logoWide;

  if (url) {
    return (
      <Link to={url}>
        <img
          className={cn({ [className]: className })}
          src={$logo}
          alt="ipay-wupos"
        />
      </Link>
    );
  }
  return (
    <img
      className={cn({ [className]: className })}
      src={$logo}
      alt="ipay-wupos"
    />
  );
}

Brand.defaultProps = {
  url: '/',
  className: 'h-8 w-auto',
  type: 'small',
};

Brand.propTypes = {
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Brand;
