import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HiEyeSlash } from 'react-icons/hi2';
import { IoCloseSharp, IoCart } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { HiEye, HiPencil, HiPlus, HiCurrencyDollar, HiArrowDown } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';
import { BsFileBarGraphFill } from 'react-icons/bs';
import { AiOutlineCloudUpload } from "react-icons/ai";

const renderColor = {
  info: 'info',
  gray: 'gray',
  dark: 'dark',
  line: 'line',
  white: 'white',
  light: 'light',
  danger: 'danger',
  primary: 'primary',
  success: 'success',
  warning: 'warning',
  outline: 'outline',
};

const renderFocus = {
  info: 'focus-info',
  gray: 'focus-gray',
  dark: 'focus-dark',
  line: 'focus-line',
  light: 'focus-light',
  white: 'focus-white',
  danger: 'focus-danger',
  primary: 'focus-primary',
  success: 'focus-success',
  warning: 'focus-warning',
  outline: 'focus-outline',
};

const renderSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const renderIconSize = {
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-5 w-5',
  lg: 'h-6 w-6',
};

const renderIcon = {
  cart: IoCart,
  currency: HiCurrencyDollar,
  view: HiEye,
  create: HiPlus,
  update: HiPencil,
  unview: HiEyeSlash,
  close: IoCloseSharp,
  download: IoMdDownload,
  remove: MdDeleteForever,
  report: BsFileBarGraphFill,
  default: HiEye,
  upload: AiOutlineCloudUpload,
  more: HiArrowDown,
};

const dispatchIcon = (icon, size) => {
  if (typeof icon === 'string') {
    const Icon = renderIcon[icon] || renderIcon?.default;
    return <Icon className={renderIconSize[size]} />;
  }
  if (typeof icon === 'object') {
    return icon;
  }
  return null;
};

function Button({
  to,
  size,
  icon,
  focus,
  color,
  label,
  isLoading,
  withLoader,
  ...rest
}) {
  const renderLabel = () => {
    if (icon) {
      return (
        <div className="flex items-center gap-1">
          {dispatchIcon(icon, size)}
          {label}
        </div>
      );
    }
    return label;
  };

  if (to) {
    return (
      <Link
        to={to}
        disabled={isLoading}
        {...rest}
        className={cn('duration-300 ease-out-in transition', {
          [renderSize[size]]: size,
          [`btn ${[renderColor[color]]}`]: color,
          [`${rest?.className}`]: rest?.className,
          '!cursor-wait': isLoading,
        })}
      >
        {renderLabel()}
      </Link>
    );
  }

  return (
    <button
      type="button"
      disabled={isLoading}
      {...rest}
      className={cn('duration-300 ease-out-in transition', {
        [renderSize[size]]: size,
        [`btn ${[renderColor[color]]}`]: color,
        [renderFocus[color]]: focus,
        [`${rest?.className}`]: rest?.className,
        '!cursor-wait': isLoading,
      })}
    >
      {isLoading && withLoader ? 'Loading...' : renderLabel()}
    </button>
  );
}

Button.defaultProps = {
  to: false,
  size: 'md',
  icon: false,
  focus: false,
  label: false,
  color: false,
  isLoading: false,
  withLoader: false,
};

Button.propTypes = {
  focus: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Function),
  ]),
  isLoading: PropTypes.bool,
  withLoader: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Button;
