import React from 'react';

import { ROOT_ACTION } from '../_constants';
import FileSection from '../sections/FileSection';
import CropperSection from '../sections/CropperSection';

function Preview() {
  const { file } = React.useContext(ROOT_ACTION);

  const min_type = file?.type?.split('/')[0];

  const dispatchSection = {
    image: <CropperSection />,
    default: <FileSection />,
  };

  return dispatchSection[min_type] || dispatchSection?.default;
}
Preview.defaultProps = {};

Preview.propTypes = {};

export default Preview;
