import React from "react";

import bannerLogo from "assets/images/banner-logo.png";

function ThemePageHeader() {
  return (
    <div className="w-full page-header">
      <div className="page-header-inner">
        <div className="page-header-logo">
          <a href="https://galanta.com" className="brand">
            <img src={bannerLogo} width="120" height="100" alt="Galanta, payment and rewards" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ThemePageHeader;
