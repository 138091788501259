import React from 'react';

import { Cropper, ImageRestriction } from 'react-advanced-cropper';

import { submitFile } from '../_actions';
import { ROOT_ACTION } from '../_constants';
import CloseButton from '../components/CloseButton';
import SectionTitle from '../components/SectionTitle';

const defaultSize = ({ imageSize, visibleArea }) => ({
  width: (visibleArea || imageSize).width,
  height: (visibleArea || imageSize).height,
});

function CropperSection() {
  const cropperRef = React.useRef();

  const { onClose, file, options } = React.useContext(ROOT_ACTION);

  const handleOnUpload = async (e) => {
    e.preventDefault();
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      await canvas.toBlob(async (blob) => {
        if (blob) {
          const args = new File([blob], file?.file_name, { type: blob.type });
          submitFile(args, options);
        }
      });
    }
    onClose();
  };

  return (
    <>
      <div className="flex items-center">
        <SectionTitle title="Crop and add this image" />
        <CloseButton />
      </div>
      <div className="w-full h-full flex flex-col flex-1 space-y-3">
        <Cropper
          ref={cropperRef}
          src={file && file.src}
          className="w-[760px] h-[578px] shadow-inner"
          stencilProps={{
            aspectRatio: 1,
            grid: true,
            movable: true,
            resizable: true,
          }}
          defaultSize={defaultSize}
          imageRestriction={ImageRestriction.fitArea}
        />
        <div className="flex-1 flex justify-between items-center">
          <button
            type="button"
            className="rounded w-28 py-2 text-gray-700 border border-bg-gray-700 hover:bg-gray-600 hover:text-white transition duration-200 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="rounded w-28 py-2 text-white bg-gray-700 hover:bg-gray-500 transition duration-200 ease-in-out"
            onClick={handleOnUpload}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}
CropperSection.defaultProps = {};

CropperSection.propTypes = {};

export default CropperSection;
