import React from 'react';

import 'assets/styles/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom/client';
import duration from 'dayjs/plugin/duration';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import SessionProvider from 'context/session';
import ErrorBoundryWrapper from 'layouts/error-boundry';
import QueryProvider, { AxiosInterceptor } from 'services';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://e6fa2be7c98d4330afb6dbac0a350703@o4504262816432128.ingest.sentry.io/4504262915653632',
  environment: import.meta.env.VITE_ENVIRONMENT || 'production',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

dayjs.extend(duration);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(LocalizedFormat);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryProvider>
    <BrowserRouter>
      <ErrorBoundryWrapper>
        <SessionProvider>
          <AxiosInterceptor>
            <App />
          </AxiosInterceptor>
        </SessionProvider>
      </ErrorBoundryWrapper>
    </BrowserRouter>
  </QueryProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
