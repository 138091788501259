import React from 'react';

import dayjs from 'dayjs';
import cn from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { IoCalendarSharp } from 'react-icons/io5';

import useOnClickOutside from '../../hooks/useOnClickOutside';

function DropDown({ options, value, onChange, name, type }) {
  const ref = React.useRef();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);
  const [form, setForm] = React.useState();

  const handleClick = (item) => () => {
    onChange(item);
    setShowDropdown(!showDropdown);
  };

  useOnClickOutside(ref, setShowDropdown);

  const handleChange = (e) => {
    let $value = e.target.value;

    if (type === 'number') $value = $value?.replace(/[^0-9.]/g, '');
    if (`${$value}` !== `${form}`) setHasChanged(true);

    setForm($value);
  };

  const renderOptions = () => {
    let $options = options;

    if (hasChanged) {
      $options = $options?.filter((item) => item?.label?.includes(form));
    }

    if (!$options?.length) {
      return <div className="py-1 text-xs font-normal">No Result</div>;
    }

    return $options?.map((item) => (
      <div
        key={item?.value}
        role="presentation"
        className={cn('p-1.5 pl-2 cursor-pointer', {
          'bg-primary-500 text-white': value === item?.value,
          'hover:bg-gray-100': value !== item?.value,
        })}
        onClick={handleClick(item?.value)}
      >
        {item?.label}
      </div>
    ));
  };

  React.useEffect(
    () =>
      setForm(options?.find((item) => `${item?.value}` === `${value}`)?.label),
    [options, value]
  );

  React.useEffect(() => {
    if (showDropdown === false) setHasChanged(false);
  }, [showDropdown]);

  return (
    <div className="w-full font-medium" ref={ref}>
      <input
        className={cn(
          'border p-1 px-3 cursor-pointer hover:border-gray-500 rounded-md text-center w-full',
          {
            'border-gray-500': showDropdown,
          }
        )}
        type="text"
        name={name}
        value={form || ''}
        onChange={handleChange}
        onClick={() => setShowDropdown(!showDropdown)}
      />

      <div className="relative">
        {showDropdown && (
          <div className="absolute top-0 left-0 w-full bg-white max-h-64 overflow-auto border shadow-sm rounded divide-y mt-1">
            {renderOptions()}
          </div>
        )}
      </div>
    </div>
  );
}

DropDown.defaultProps = {
  type: 'text',
};

DropDown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const renderPlaceholder = (from, placeholder) => {
  const formatter = (value) => dayjs(value).format('MMM DD, YYYY');

  if (!from) return placeholder;
  if (formatter(from) === formatter()) return 'Today';

  return from;
};

function FormInputYear({
  value: dateFrom,
  dateFormat,
  textFormat,
  name,
  label,
  disabled,
  labelClassName,
  containerClassName,
  required,
  error,
  onChange,
  placeholder,
  onSetFieldValue,
  yearLength,
  top,
  ...rest
}) {
  const [isShow, setIfShow] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);

  const ref = React.useRef(null);
  useOnClickOutside(ref, setIfShow);

  // Placeholder date
  const [from, setFrom] = React.useState(
    dateFrom ? dayjs(dateFrom).format(textFormat) : dateFrom
  );

  // Temporary date
  const [startDate, setStartDate] = React.useState(
    dateFrom ? new Date(dateFrom) : new Date()
  );

  // Temporary date format
  const startDateFormat = React.useMemo(
    () => dayjs(startDate).format(textFormat),
    [startDate, textFormat]
  );

  const handleShowDate = () => {
    if (!isShow) {
      setIfShow(true);
      setTimeout(() => {
        setAnimate(true);
      }, 100);
      return;
    }
    setAnimate(false);
    setTimeout(() => {
      setIfShow(false);
    }, 100);
  };

  const handleChange = (value) => setStartDate(value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFrom(startDateFormat);
    handleShowDate();
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, dayjs(startDate).format(dateFormat));
      return;
    }
    if (typeof onChange === 'function')
      onChange((prevState) => ({
        ...prevState,
        [name]: dayjs(startDate).format(dateFormat),
      }));
  };
  const handleCancel = (e) => {
    const f = dateFrom ? dayjs(dateFrom).format(textFormat) : dateFrom;
    setFrom(f);
    setStartDate(dateFrom ? new Date(dateFrom) : new Date());
    handleShowDate();
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, (f, e));
      return;
    }
    if (typeof onChange === 'function') onChange({ [name]: (f, e) });
  };

  return (
    <div>
      <div className="w-full relative" ref={ref}>
        {label && (
          <label
            className={cn('form-label', labelClassName)}
            htmlFor={rest?.id ?? name}
          >
            {label} {required ? <span className="text-red-500">*</span> : ''}
          </label>
        )}
        <button
          className={cn(
            'transition duration-300 ease-in-out flex space-x-2 disabled:bg-gray-100 disabled:opacity-100',
            {
              'form-input': !error,
              'form-input-error': error,
            }
          )}
          onClick={handleShowDate}
          type="button"
          disabled={disabled}
          id={rest?.id ?? name}
        >
          <IoCalendarSharp className="h-5 w-5" />
          <div className="flex-1 text-left">
            {renderPlaceholder(from, placeholder)}
          </div>
        </button>
        {error && (
          <small className="flex text-xs absolute -bottom-2 right-2 px-2 bg-red-50 border- rounded text-red-500">
            {error ?? ''}
          </small>
        )}
        {isShow && (
          <div
            className={cn(
              'transition transform ease-out duration-100 origin-top z-40 absolute right-0 mt-1 to flex flex-col bg-white border border-gray-100 rounded-md shadow h-auto overflow-hidden p-6 pt-3',
              {
                'opacity-0 scale-95': !animate,
                'opacity-100 scale-100': animate,
              },
              {
                'bottom-12': top,
              }
            )}
          >
            <DatePicker
              inline
              selected={startDate}
              onChange={handleChange}
              focusSelectedMonth={false}
              id={`${name}_date`}
              name={`${name}_date`}
              calendarClassName="customize-datepicker-s-theme"
              showYearPicker
              disabledKeyboardNavigation
              {...rest}
            />

            <div className="flex w-full gap-2 mt-2">
              <div>
                <button
                  type="button"
                  className="rounded-md border p-2 px-4"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
              <div className="flex-1">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="rounded-md p-2 w-full border bg-primary-500 text-white"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

FormInputYear.defaultProps = {
  value: '',
  textFormat: 'YYYY',
  dateFormat: 'YYYY',
  label: '',
  labelClassName: '',
  containerClassName: '',
  required: false,
  error: false,
  placeholder: 'Select year',
  onChange: false,
  onSetFieldValue: false,
  disabled: false,
  yearLength: 50,
  top: false,
};

FormInputYear.propTypes = {
  textFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onChange: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.bool,
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  yearLength: PropTypes.number,
  top: PropTypes.bool,
};

export default FormInputYear;
