import React from "react";

function ThemePageFooter() {
  return (
    <div className="w-full page-footer">
      <footer className="page-footer-inner">
        <div className="footer-list">
          <a href="https://www.instagram.com/galantahq/?hl=en" target="_blank" className="footer-link" rel="noreferrer">Instagram</a>
          <a href="https://www.facebook.com/galantahq" target="_blank" className="footer-link" rel="noreferrer">Facebook</a>
          <a href="https://twitter.com/galantatech" target="_blank" className="footer-link" rel="noreferrer">Twitter</a>
          <a href="mailto:admin@galanta.com?subject=New%20Enquiry" className="footer-link">Contact:admin@galanta.com</a>
          <a href="https://www.galanta.com/privacy-policy.html" className="footer-link">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
}

export default ThemePageFooter;
